import React from 'react';
import ComingSoon from './ComingSoon';

export default function CaseStudies() {
  return (
    <ComingSoon
      title="Case Studies Coming Soon"
      description="Explore our upcoming collection of detailed case studies showcasing successful advertising campaigns and their measurable results across various industries and channels."
      image="https://images.unsplash.com/photo-1460925895917-afdab827c52f"
    />
  );
}