import React, { useState } from 'react';
import { MessageSquare, Sparkles, Brain, ArrowRight, X } from 'lucide-react';
import LeadForm from '../campaign/LeadForm';
import SuccessMessage from '../campaign/SuccessMessage';

interface QuickCampaignProps {
  onClose: () => void;
}

export default function QuickCampaign({ onClose }: QuickCampaignProps) {
  const [showAIChat, setShowAIChat] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [messages, setMessages] = useState<Array<{role: string; content: string}>>([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [campaignPlan, setCampaignPlan] = useState<any>(null);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim()) return;

    const userMessage = { role: 'user', content: input };
    setMessages(prev => [...prev, userMessage]);
    setInput('');
    setLoading(true);

    try {
      const response = await fetch('/api/ai/campaign-planner', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          messages: [...messages, userMessage]
        })
      });

      const data = await response.json();
      setMessages(prev => [...prev, { role: 'assistant', content: data.message }]);
      
      if (data.plan) {
        setCampaignPlan(data.plan);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prev => [...prev, {
        role: 'assistant',
        content: 'Sorry, I encountered an error. Please try again.'
      }]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Quick Campaign</h1>
        <button
          onClick={onClose}
          className="p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
        >
          <X className="h-6 w-6" />
        </button>
      </div>

      {!showAIChat ? (
        <div className="text-center mb-12">
          <div className="bg-gradient-to-br from-purple-600 to-indigo-600 text-white rounded-lg p-8 shadow-lg">
            <div className="flex items-center justify-center mb-4">
              <Brain className="h-12 w-12" />
            </div>
            <h2 className="text-2xl font-bold mb-4">
              Try Our AI-Powered Campaign Planner
            </h2>
            <p className="text-lg mb-6">
              Get instant, data-driven recommendations for your campaign strategy
            </p>
            <button 
              onClick={() => setShowAIChat(true)}
              className="bg-white text-purple-600 px-6 py-3 rounded-lg font-medium hover:bg-gray-50 transition-colors inline-flex items-center"
            >
              <Sparkles className="h-5 w-5 mr-2" />
              Start AI Planning
            </button>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm p-6">
          <div className="flex items-center mb-6">
            <MessageSquare className="h-6 w-6 text-purple-600 mr-2" />
            <h2 className="text-lg font-medium text-gray-900">AI Campaign Planner</h2>
          </div>

          <div className="mb-6 h-96 overflow-y-auto space-y-4">
            {messages.map((message, index) => (
              <div
                key={index}
                className={`p-4 rounded-lg ${
                  message.role === 'user'
                    ? 'bg-purple-50 ml-12'
                    : 'bg-gray-50 mr-12'
                }`}
              >
                <p className="text-gray-900">{message.content}</p>
              </div>
            ))}
            {loading && (
              <div className="flex justify-center">
                <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-purple-600" />
              </div>
            )}
          </div>

          <form onSubmit={handleSubmit} className="flex gap-4">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Tell me about your campaign goals..."
              className="flex-1 input"
              disabled={loading}
            />
            <button
              type="submit"
              className="btn-primary"
              disabled={loading}
            >
              <ArrowRight className="h-5 w-5" />
            </button>
          </form>

          {campaignPlan && (
            <div className="mt-8 p-6 bg-purple-50 rounded-lg">
              <h2 className="text-lg font-medium text-gray-900 mb-4">Ready to move forward?</h2>
              <p className="text-gray-600 mb-4">
                Share your contact details to receive a detailed proposal and connect with our team.
              </p>
              <LeadForm onSubmit={() => setShowSuccess(true)} campaignPlan={campaignPlan} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}