export const MOCK_DIGITAL = [
  {
    id: 'yt-1',
    title: 'YouTube Premium Pre-Roll Ads',
    type: 'youtube',
    specifications: {
      format: 'Video Ad',
      duration: '15-30 seconds',
      targeting: 'Custom Audience',
      platform: 'YouTube'
    },
    pricing: {
      basePrice: 100000,
      unit: 'per_campaign',
      minimumBudget: 100000
    },
    features: [
      'Skip Option After 5s',
      'Custom Audience Targeting',
      'Performance Analytics',
      'Brand Safety Controls'
    ],
    metrics: {
      avgViews: '500,000+',
      engagementRate: '15%',
      completion: '70%'
    },
    availability: {
      status: 'available'
    }
  },
  {
    id: 'prog-1',
    title: 'Programmatic Display Network',
    type: 'programmatic',
    specifications: {
      format: 'Display Ads',
      sizes: ['300x250', '728x90', '160x600'],
      targeting: 'RTB',
      platform: 'DV360'
    },
    pricing: {
      basePrice: 50000,
      unit: 'per_campaign',
      minimumBudget: 50000
    },
    features: [
      'Real-time Bidding',
      'Advanced Targeting',
      'Brand Safety',
      'Viewability Tracking'
    ],
    metrics: {
      impressions: '1M+',
      clickRate: '0.8%',
      viewability: '75%'
    },
    availability: {
      status: 'available'
    }
  },
  {
    id: 'ott-1',
    title: 'Hotstar Premium Video Ads',
    type: 'ott',
    specifications: {
      format: 'Video Ad',
      duration: '20 seconds',
      targeting: 'Premium Content',
      platform: 'Hotstar'
    },
    pricing: {
      basePrice: 200000,
      unit: 'per_campaign',
      minimumBudget: 200000
    },
    features: [
      'Premium Content Placement',
      'Regional Targeting',
      'Live Sports Events',
      'Non-skippable'
    ],
    metrics: {
      reach: '10M+',
      completion: '95%',
      targetAudience: 'Premium'
    },
    availability: {
      status: 'available'
    }
  }
];

export const DIGITAL_CATEGORIES = [
  {
    id: 'youtube',
    name: 'YouTube Advertising',
    description: 'Video ads on the world\'s largest video platform',
    icon: 'Youtube'
  },
  {
    id: 'programmatic',
    name: 'Programmatic Display',
    description: 'Automated buying across premium websites',
    icon: 'Globe'
  },
  {
    id: 'ott',
    name: 'OTT/CTV',
    description: 'Video ads on streaming platforms',
    icon: 'Tv'
  },
  {
    id: 'social',
    name: 'Social Media Ads',
    description: 'Advertising across major social platforms',
    icon: 'Share2'
  }
];