import React from 'react';
import ComingSoon from './ComingSoon';

export default function MediaKit() {
  return (
    <ComingSoon
      title="Media Kit Coming Soon"
      description="Our comprehensive media kit with detailed information about our platform, audience reach, and advertising opportunities will be available soon."
      image="https://images.unsplash.com/photo-1434626881859-194d67b2b86f"
    />
  );
}