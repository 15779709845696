import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqs = [
  {
    question: "What types of advertising media does Sigmaverse offer?",
    answer: "Sigmaverse offers a comprehensive range of advertising media including Television, Radio, Print Media (Newspapers & Magazines), Out-of-Home (OOH), Digital Media, Cinema Advertising, and BTL Activations. We cover all major cities and regions across India."
  },
  {
    question: "How does the AI-powered campaign planning work?",
    answer: "Our AI system analyzes your campaign objectives, target audience, and budget to recommend the optimal media mix. It considers factors like historical performance data, seasonal trends, and audience behavior to create effective campaign strategies."
  },
  {
    question: "What is the minimum budget required to start advertising?",
    answer: "The minimum budget varies depending on the media type and location. We work with businesses of all sizes and can create customized plans that fit your budget while maximizing impact. Contact our team for detailed pricing information."
  },
  {
    question: "How do I track my campaign performance?",
    answer: "Sigmaverse provides a comprehensive dashboard where you can track real-time campaign performance metrics, including reach, engagement, and ROI. You'll also receive regular reports and insights to optimize your campaigns."
  }
];

export default function FAQ() {
  const [openIndex, setOpenIndex] = useState<number | null>(null);

  return (
    <div className="bg-white py-16">
      <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold text-gray-900 mb-12">
          Frequently Asked Questions
        </h2>
        
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div 
              key={index}
              className="border border-gray-200 rounded-lg"
            >
              <button
                className="w-full flex items-center justify-between p-4 text-left"
                onClick={() => setOpenIndex(openIndex === index ? null : index)}
              >
                <span className="font-medium text-gray-900">{faq.question}</span>
                {openIndex === index ? (
                  <ChevronUp className="h-5 w-5 text-gray-500" />
                ) : (
                  <ChevronDown className="h-5 w-5 text-gray-500" />
                )}
              </button>
              
              {openIndex === index && (
                <div className="px-4 pb-4">
                  <p className="text-gray-600">{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>

        <div className="mt-8 text-center">
          <p className="text-gray-600">
            Have more questions? Check our{' '}
            <a href="/faq" className="text-purple-600 hover:text-purple-500">
              complete FAQ page
            </a>
            {' '}or{' '}
            <a href="/contact" className="text-purple-600 hover:text-purple-500">
              contact us
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}