import React from 'react';
import { Building } from 'lucide-react';

interface ProductHeaderProps {
  title: string;
  mediaOwner: string;
  status: string;
}

export default function ProductHeader({ title, mediaOwner, status }: ProductHeaderProps) {
  return (
    <div className="mb-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">{title}</h1>
        <span className={`px-3 py-1 text-sm font-medium rounded-full ${
          status === 'available'
            ? 'bg-green-100 text-green-800'
            : 'bg-yellow-100 text-yellow-800'
        }`}>
          {status}
        </span>
      </div>
      <div className="flex items-center mt-2">
        <Building className="h-4 w-4 text-gray-400 mr-2" />
        <span className="text-sm text-gray-600">{mediaOwner}</span>
      </div>
    </div>
  );
}