import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Calendar, Users, MapPin, DollarSign, Star, Building, CheckCircle, Plus } from 'lucide-react';
import { MOCK_EVENTS } from '../../../data/mockEvents';
import AddToCampaignModal from '../../../components/AddToCampaignModal';

export default function EventsProduct() {
  const { id } = useParams();
  const [selectedSponsorship, setSelectedSponsorship] = useState<string | null>(null);
  const [showAddToCampaign, setShowAddToCampaign] = useState(false);
  
  const event = MOCK_EVENTS.find(event => event.id === id);

  if (!event) {
    return <div>Event not found</div>;
  }

  const handleAddToCampaign = (sponsorshipId: string) => {
    setSelectedSponsorship(sponsorshipId);
    setShowAddToCampaign(true);
  };

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="p-6">
          <div className="relative h-96 mb-4 rounded-lg overflow-hidden">
            <img
              src={event.images[0]}
              alt={event.title}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="grid grid-cols-4 gap-2">
            {event.images.slice(1).map((image, index) => (
              <div key={index} className="relative h-20 rounded-lg overflow-hidden">
                <img
                  src={image}
                  alt={`${event.title} ${index + 2}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900">{event.title}</h1>
            <div className="flex items-center mt-2">
              <Calendar className="h-4 w-4 text-gray-400 mr-2" />
              <span className="text-sm text-gray-600">
                {new Date(event.dates.start).toLocaleDateString()} - {new Date(event.dates.end).toLocaleDateString()}
              </span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Users className="h-4 w-4 mr-2" />
                Expected Attendance
              </div>
              <p className="text-lg font-semibold">
                {event.specifications.expectedAttendance}
              </p>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <MapPin className="h-4 w-4 mr-2" />
                Location
              </div>
              <p className="text-lg font-semibold">
                {event.location.city}
              </p>
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Event Details</h2>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(event.specifications).map(([key, value]) => (
                <div key={key} className="text-sm">
                  <span className="text-gray-600">
                    {key.split('_').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}:
                  </span>
                  <span className="ml-2 font-medium">{value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Sponsorship Packages</h2>
            <div className="space-y-4">
              {event.sponsorshipOptions.map((option) => (
                <div key={option.id} className="border rounded-lg p-4">
                  <div className="flex justify-between items-start mb-4">
                    <div>
                      <h3 className="font-medium text-gray-900">{option.type}</h3>
                      <p className="text-lg font-bold text-purple-600 mt-1">
                        ₹{option.price.toLocaleString()}
                      </p>
                    </div>
                    <button
                      onClick={() => handleAddToCampaign(option.id)}
                      className="btn-primary flex items-center text-sm"
                    >
                      <Plus className="h-4 w-4 mr-1" />
                      Add to Campaign
                    </button>
                  </div>

                  <div className="space-y-4">
                    <div>
                      <h4 className="text-sm font-medium text-gray-900 mb-2">Deliverables</h4>
                      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div>
                          <h5 className="text-xs font-medium text-gray-700 mb-2">Branding</h5>
                          <ul className="space-y-1">
                            {option.deliverables.branding.map((item, index) => (
                              <li key={index} className="flex items-center text-xs text-gray-600">
                                <CheckCircle className="h-3 w-3 text-green-500 mr-1" />
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h5 className="text-xs font-medium text-gray-700 mb-2">Media</h5>
                          <ul className="space-y-1">
                            {option.deliverables.media.map((item, index) => (
                              <li key={index} className="flex items-center text-xs text-gray-600">
                                <CheckCircle className="h-3 w-3 text-green-500 mr-1" />
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div>
                          <h5 className="text-xs font-medium text-gray-700 mb-2">Activation</h5>
                          <ul className="space-y-1">
                            {option.deliverables.activation.map((item, index) => (
                              <li key={index} className="flex items-center text-xs text-gray-600">
                                <CheckCircle className="h-3 w-3 text-green-500 mr-1" />
                                {item}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {showAddToCampaign && selectedSponsorship && (
        <AddToCampaignModal
          inventory={{
            id: selectedSponsorship,
            type: 'Event Sponsorship',
            location: event.location.city,
            price: event.sponsorshipOptions.find(opt => opt.id === selectedSponsorship)?.price || 0,
            mediaOwner: {
              id: event.id,
              name: event.title
            }
          }}
          onClose={() => setShowAddToCampaign(false)}
          onAdd={(campaignId, details) => {
            console.log('Adding to campaign:', campaignId, details);
            setShowAddToCampaign(false);
          }}
          existingCampaigns={[]}
        />
      )}
    </div>
  );
}