import React from 'react';
import { useParams } from 'react-router-dom';
import { Book, Users, Globe, DollarSign, Calendar, Building } from 'lucide-react';
import { MOCK_MAGAZINES } from '../../../data/mockMagazines';

export default function MagazineProduct() {
  const { id } = useParams();
  const magazine = MOCK_MAGAZINES.find(mag => mag.id === id);

  if (!magazine) {
    return <div>Magazine not found</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="p-6">
          <div className="relative h-96 mb-4 rounded-lg overflow-hidden">
            <img
              src={magazine.image}
              alt={magazine.name}
              className="w-full h-full object-cover"
            />
          </div>
          
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Globe className="h-4 w-4 mr-2" />
                Language
              </div>
              <p className="text-lg font-semibold">{magazine.language}</p>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Users className="h-4 w-4 mr-2" />
                Circulation
              </div>
              <p className="text-lg font-semibold">{magazine.circulation}</p>
            </div>
          </div>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <h1 className="text-2xl font-bold text-gray-900 mb-2">{magazine.name}</h1>
            <div className="flex items-center">
              <Calendar className="h-4 w-4 text-gray-400 mr-2" />
              <span className="text-sm text-gray-600">{magazine.frequency} Publication</span>
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Available Ad Spots</h2>
            <div className="space-y-4">
              {magazine.adSpots.map((spot) => (
                <div key={spot.id} className="border rounded-lg p-4">
                  <div className="flex justify-between items-center mb-2">
                    <h3 className="font-medium text-gray-900">{spot.type}</h3>
                    <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                      spot.availability === 'available'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800'
                    }`}>
                      {spot.availability}
                    </span>
                  </div>
                  <div className="grid grid-cols-2 gap-4 text-sm">
                    <div>
                      <span className="text-gray-600">Position:</span>
                      <span className="ml-2 font-medium">{spot.position}</span>
                    </div>
                    <div>
                      <span className="text-gray-600">Size:</span>
                      <span className="ml-2 font-medium">{spot.dimensions}</span>
                    </div>
                    <div className="col-span-2">
                      <span className="text-gray-600">Price:</span>
                      <span className="ml-2 font-medium">₹{spot.price.toLocaleString()}</span>
                    </div>
                  </div>
                  <button className="mt-4 w-full btn-primary">
                    Book This Spot
                  </button>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Features & Benefits</h2>
            <div className="flex flex-wrap gap-2">
              {magazine.features.map((feature) => (
                <span
                  key={feature}
                  className="px-3 py-1 bg-purple-50 text-purple-700 text-sm rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </div>

          <div className="flex space-x-4">
            <button className="flex-1 btn-primary">
              Download Media Kit
            </button>
            <button className="flex-1 btn-secondary">
              Contact Publisher
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}