import React from 'react';
import { Calendar, Mail, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface ComingSoonProps {
  title: string;
  description: string;
  image?: string;
}

export default function ComingSoon({ title, description, image }: ComingSoonProps) {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
      <div className="text-center">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">{title}</h1>
        <p className="text-xl text-gray-600 mb-8">{description}</p>
        
        {image && (
          <img
            src={image}
            alt={title}
            className="w-full max-w-lg mx-auto mb-8 rounded-lg"
          />
        )}

        <div className="bg-purple-50 rounded-lg p-8 mb-8">
          <h2 className="text-2xl font-bold text-purple-900 mb-4">
            Stay Updated
          </h2>
          <p className="text-purple-700 mb-6">
            Subscribe to our newsletter to be notified when this feature launches.
          </p>
          <form className="max-w-md mx-auto">
            <div className="flex gap-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="flex-1 input"
              />
              <button type="submit" className="btn-primary">
                Subscribe
              </button>
            </div>
          </form>
        </div>

        <div className="flex justify-center space-x-4">
          <Link to="/contact" className="btn-secondary inline-flex items-center">
            <Mail className="h-4 w-4 mr-2" />
            Contact Us
          </Link>
          <Link to="/" className="btn-secondary inline-flex items-center">
            <ArrowRight className="h-4 w-4 mr-2" />
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
}