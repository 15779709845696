import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { MapPin, Users, Globe, DollarSign } from 'lucide-react';
import { MOCK_INVENTORY } from '../../../data/mockInventory';

export default function NewspaperInventory() {
  const { subcategory } = useParams();
  const inventory = MOCK_INVENTORY.filter(
    item => item.category === 'newspaper' && item.subcategory === subcategory
  );

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">
          {subcategory?.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')} Inventory
        </h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>Sort by Circulation</option>
            <option>Sort by Price</option>
            <option>Sort by Rating</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {inventory.map((item) => (
          <Link
            key={item.id}
            to={`/discover/newspaper/${subcategory}/${item.id}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all"
          >
            <div className="relative h-48">
              <img
                src={item.images[0]}
                alt={item.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-2 right-2">
                <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                  item.availability.status === 'available'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {item.availability.status}
                </span>
              </div>
            </div>

            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {item.title}
              </h3>

              <div className="space-y-2 mb-4">
                <div className="flex items-center text-sm text-gray-600">
                  <Globe className="h-4 w-4 mr-2" />
                  {item.specifications.circulation} Daily Circulation
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <MapPin className="h-4 w-4 mr-2" />
                  {item.location.city}, {item.location.state}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <DollarSign className="h-4 w-4 mr-2" />
                  ₹{item.pricing.basePrice.toLocaleString()} {item.pricing.unit}
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {item.features.slice(0, 2).map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}