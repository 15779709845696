import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AirportList from './AirportList';
import AirportInventory from './AirportInventory';
import AirportProduct from './AirportProduct';

export default function AirportCategory() {
  return (
    <Routes>
      <Route index element={<AirportList />} />
      <Route path=":airportId" element={<AirportInventory />} />
      <Route path=":airportId/:inventoryId" element={<AirportProduct />} />
    </Routes>
  );
}