import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Home from '../pages/Home';
import About from '../pages/About';
import Contact from '../pages/Contact';
import SignIn from '../pages/Auth/SignIn';
import SignUp from '../pages/Auth/SignUp';
import Blog from '../pages/Blog';
import Article from '../pages/Blog/Article';
import CategoryLayout from '../pages/Category/Layout';
import CategoryRoutes from './CategoryRoutes';
import AdvertiserSolutions from '../pages/solutions/AdvertiserSolutions';
import MediaOwnerSolutions from '../pages/solutions/MediaOwnerSolutions';
import DigitalMarketing from '../pages/solutions/DigitalMarketing';
import PrivacyPolicy from '../pages/PrivacyPolicy';
import TermsOfService from '../pages/TermsOfService';
import CookiePolicy from '../pages/CookiePolicy';
import Disclaimer from '../pages/Disclaimer';
import CaseStudies from '../pages/CaseStudies';
import IndustryReports from '../pages/IndustryReports';
import Webinars from '../pages/Webinars';
import MediaKit from '../pages/MediaKit';
import FAQ from '../pages/FAQ';

export default function PublicRoutes() {
  return (
    <>
      <Header />
      <Routes>
        <Route index element={<Home />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="signin" element={<SignIn />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:slug" element={<Article />} />
        <Route path="advertiser" element={<AdvertiserSolutions />} />
        <Route path="media-owner" element={<MediaOwnerSolutions />} />
        <Route path="digital" element={<DigitalMarketing />} />
        <Route path="privacy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsOfService />} />
        <Route path="cookies" element={<CookiePolicy />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="case-studies" element={<CaseStudies />} />
        <Route path="industry-reports" element={<IndustryReports />} />
        <Route path="webinars" element={<Webinars />} />
        <Route path="media-kit" element={<MediaKit />} />
        <Route path="faq" element={<FAQ />} />
        <Route path="category/*" element={<CategoryLayout />}>
          <Route path="*" element={<CategoryRoutes />} />
        </Route>
      </Routes>
      <Footer />
    </>
  );
}