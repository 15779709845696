export const MOCK_TV = [
  {
    id: 'star-plus',
    name: 'Star Plus',
    language: 'Hindi',
    category: 'Entertainment',
    logo: 'https://images.unsplash.com/photo-1611162617474-5b21e879e113',
    reach: '15Cr+ Monthly',
    priceRange: '₹50,000 - ₹5,00,000',
    inventory: [
      {
        id: 'sp-1',
        type: 'Prime Time Spot',
        duration: '30 seconds',
        timeSlot: '8 PM - 10 PM',
        price: 450000,
        availability: 'available'
      },
      {
        id: 'sp-2',
        type: 'Aston Band',
        duration: '10 seconds',
        timeSlot: 'All Day',
        price: 150000,
        availability: 'available'
      }
    ],
    features: ['High TRP', 'National Reach', 'Premium Content']
  },
  {
    id: 'sony',
    name: 'Sony Entertainment',
    language: 'Hindi',
    category: 'Entertainment',
    logo: 'https://images.unsplash.com/photo-1611162616305-c69b3037c7bb',
    reach: '12Cr+ Monthly',
    priceRange: '₹45,000 - ₹4,50,000',
    inventory: [
      {
        id: 'sony-1',
        type: 'Reality Show Integration',
        duration: '60 seconds',
        timeSlot: 'Prime Time',
        price: 550000,
        availability: 'available'
      },
      {
        id: 'sony-2',
        type: 'L-Band',
        duration: '15 seconds',
        timeSlot: 'All Day',
        price: 175000,
        availability: 'available'
      }
    ],
    features: ['Reality Shows', 'Sports Events', 'Family Entertainment']
  }
];

export const TV_CATEGORIES = [
  {
    id: 'entertainment',
    name: 'Entertainment Channels',
    description: 'Leading entertainment channels with high viewership',
    icon: 'Tv'
  },
  {
    id: 'news',
    name: 'News Channels',
    description: '24x7 news coverage across languages',
    icon: 'Globe'
  },
  {
    id: 'sports',
    name: 'Sports Channels',
    description: 'Live sports and premium sporting events',
    icon: 'Trophy'
  },
  {
    id: 'movies',
    name: 'Movie Channels',
    description: 'Premium movie channels across languages',
    icon: 'Film'
  }
];