import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { MapPin, Users, Clock, DollarSign, Search } from 'lucide-react';
import { MOCK_OOH } from '../../../data/mockOOH';

export default function OOHInventory() {
  const { subcategory } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCity, setSelectedCity] = useState('all');
  const [priceRange, setPriceRange] = useState('all');

  const cities = [...new Set(MOCK_OOH.map(item => item.location.city))];

  const filteredInventory = MOCK_OOH.filter(item => {
    const matchesSubcategory = item.type === subcategory;
    const matchesSearch = item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         item.location.area.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCity = selectedCity === 'all' || item.location.city === selectedCity;
    const matchesPriceRange = priceRange === 'all' || 
      (priceRange === 'under100k' && item.pricing.basePrice < 100000) ||
      (priceRange === '100k-300k' && item.pricing.basePrice >= 100000 && item.pricing.basePrice <= 300000) ||
      (priceRange === 'above300k' && item.pricing.basePrice > 300000);

    return matchesSubcategory && matchesSearch && matchesCity && matchesPriceRange;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">
          {subcategory?.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')}
        </h1>

        <div className="flex items-center space-x-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search locations..."
              className="pl-10 input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <select
            className="input"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="all">All Cities</option>
            {cities.map(city => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>

          <select
            className="input"
            value={priceRange}
            onChange={(e) => setPriceRange(e.target.value)}
          >
            <option value="all">All Prices</option>
            <option value="under100k">Under ₹1,00,000</option>
            <option value="100k-300k">₹1,00,000 - ₹3,00,000</option>
            <option value="above300k">Above ₹3,00,000</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredInventory.map((item) => (
          <Link
            key={item.id}
            to={`/category/ooh/${subcategory}/${item.id}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all"
          >
            <div className="relative h-48">
              <img
                src={item.images[0]}
                alt={item.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-2 right-2">
                <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                  item.availability.status === 'available'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {item.availability.status}
                </span>
              </div>
            </div>

            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {item.title}
              </h3>

              <div className="space-y-2 mb-4">
                <div className="flex items-center text-sm text-gray-600">
                  <MapPin className="h-4 w-4 mr-2" />
                  {item.location.area}, {item.location.city}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Users className="h-4 w-4 mr-2" />
                  {item.metrics.dailyTraffic || item.metrics.dailyFootfall}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <DollarSign className="h-4 w-4 mr-2" />
                  ₹{item.pricing.basePrice.toLocaleString()} {item.pricing.unit}
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {item.features.slice(0, 2).map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}