import React from 'react';
import { AlertTriangle, CheckCircle, XCircle, Clock } from 'lucide-react';

const mockApprovals = [
  {
    id: '1',
    type: 'campaign',
    title: 'Summer Collection Launch',
    client: 'Fashion Brand X',
    submittedAt: '2024-03-15',
    status: 'pending',
    budget: 250000,
  },
  {
    id: '2',
    type: 'mediaOwner',
    title: 'Premium Billboard Network',
    client: 'OutdoorAds Co.',
    submittedAt: '2024-03-14',
    status: 'pending',
    locations: ['Mumbai', 'Delhi', 'Bangalore'],
  },
];

export default function Approvals() {
  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Pending Approvals</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Types</option>
            <option>Campaigns</option>
            <option>Media Owners</option>
            <option>Content</option>
          </select>
        </div>
      </div>

      <div className="space-y-6">
        {mockApprovals.map((approval) => (
          <div key={approval.id} className="bg-white shadow-sm rounded-lg p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <div className="h-10 w-10 rounded-full bg-yellow-100 flex items-center justify-center">
                  <AlertTriangle className="h-5 w-5 text-yellow-600" />
                </div>
                <div className="ml-4">
                  <h2 className="text-lg font-medium text-gray-900">{approval.title}</h2>
                  <p className="text-sm text-gray-500">
                    Submitted by {approval.client} on{' '}
                    {new Date(approval.submittedAt).toLocaleDateString()}
                  </p>
                </div>
              </div>
              <span className="px-2 py-1 text-xs font-medium rounded-full bg-yellow-100 text-yellow-800">
                Pending Review
              </span>
            </div>

            {approval.type === 'campaign' && (
              <div className="mt-4 bg-gray-50 rounded-lg p-4">
                <div className="text-sm text-gray-600">
                  <p>Campaign Budget: ₹{approval.budget.toLocaleString()}</p>
                  <p>Duration: 30 days</p>
                </div>
              </div>
            )}

            {approval.type === 'mediaOwner' && (
              <div className="mt-4 bg-gray-50 rounded-lg p-4">
                <div className="text-sm text-gray-600">
                  <p>Locations: {approval.locations.join(', ')}</p>
                  <p>Inventory Types: Billboard, Digital Displays</p>
                </div>
              </div>
            )}

            <div className="mt-6 flex justify-end space-x-3">
              <button className="btn-secondary flex items-center">
                <Clock className="h-4 w-4 mr-2" />
                Review Later
              </button>
              <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700">
                <XCircle className="h-4 w-4 mr-2" />
                Reject
              </button>
              <button className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700">
                <CheckCircle className="h-4 w-4 mr-2" />
                Approve
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}