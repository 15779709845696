import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Plane, Search, MapPin, Users } from 'lucide-react';

interface Airport {
  id: string;
  name: string;
  code: string;
  city: string;
  footfall: string;
  inventoryCount: number;
  priceRange: string;
  image: string;
}

const mockAirports: Airport[] = [
  {
    id: 'del',
    name: 'Indira Gandhi International Airport',
    code: 'DEL',
    city: 'Delhi',
    footfall: '5Cr+ Annual',
    inventoryCount: 156,
    priceRange: '₹50,000 - ₹5,00,000',
    image: 'https://images.unsplash.com/photo-1583863788434-e58a36330cf0'
  },
  {
    id: 'bom',
    name: 'Chhatrapati Shivaji International Airport',
    code: 'BOM',
    city: 'Mumbai',
    footfall: '4.5Cr+ Annual',
    inventoryCount: 134,
    priceRange: '₹45,000 - ₹4,50,000',
    image: 'https://images.unsplash.com/photo-1583864697784-a0efc8379f70'
  }
];

export default function AirportList() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCity, setSelectedCity] = useState('');

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h1 className="text-2xl font-bold text-gray-900">Airport Advertising</h1>
        
        <div className="flex items-center space-x-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 h-5 w-5" />
            <input
              type="text"
              placeholder="Search airports..."
              className="pl-10 input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <select
            className="input"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="">All Cities</option>
            <option value="Delhi">Delhi</option>
            <option value="Mumbai">Mumbai</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Hyderabad">Hyderabad</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {mockAirports.map((airport) => (
          <Link
            key={airport.id}
            to={`/category/airport/${airport.id}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all"
          >
            <div className="relative h-48">
              <img
                src={airport.image}
                alt={airport.name}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
              <div className="absolute bottom-4 left-4 text-white">
                <h3 className="text-lg font-medium">{airport.name}</h3>
                <p className="text-sm opacity-90">{airport.code}</p>
              </div>
            </div>

            <div className="p-6">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <div className="flex items-center text-sm text-gray-600">
                    <MapPin className="h-4 w-4 mr-2" />
                    {airport.city}
                  </div>
                  <div className="flex items-center text-sm text-gray-600 mt-1">
                    <Users className="h-4 w-4 mr-2" />
                    {airport.footfall}
                  </div>
                </div>
                <div className="text-right">
                  <div className="text-sm font-medium text-gray-900">
                    {airport.inventoryCount} Ad Spots
                  </div>
                  <div className="text-sm text-gray-600 mt-1">
                    {airport.priceRange}
                  </div>
                </div>
              </div>

              <div className="mt-4 pt-4 border-t border-gray-200">
                <div className="flex flex-wrap gap-2">
                  <span className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full">
                    Digital Displays
                  </span>
                  <span className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full">
                    Static Displays
                  </span>
                  <span className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full">
                    Premium Locations
                  </span>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}