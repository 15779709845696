import React from 'react';
import ComingSoon from './ComingSoon';

export default function IndustryReports() {
  return (
    <ComingSoon
      title="Industry Reports Coming Soon"
      description="Stay tuned for comprehensive industry reports featuring market trends, insights, and analysis of the advertising landscape across India."
      image="https://images.unsplash.com/photo-1551288049-bebda4e38f71"
    />
  );
}