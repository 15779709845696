import React from 'react';

export default function TermsOfService() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
      
      <div className="prose prose-lg">
        <p className="text-gray-600 mb-6">Last updated: March 15, 2024</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. Acceptance of Terms</h2>
          <p className="text-gray-600">
            By accessing and using Sigmaverse's platform, you agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. User Accounts</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>You must provide accurate and complete information when creating an account</li>
            <li>You are responsible for maintaining the security of your account credentials</li>
            <li>You must notify us immediately of any unauthorized access</li>
            <li>We reserve the right to suspend or terminate accounts that violate our terms</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Platform Usage</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Users must comply with all applicable laws and regulations</li>
            <li>No unauthorized use of the platform for commercial purposes</li>
            <li>Users must not interfere with the platform's functionality</li>
            <li>Content posted must be accurate and not misleading</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Payments and Billing</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>All payments are processed securely through our platform</li>
            <li>Prices are subject to change with notice</li>
            <li>Refunds are handled according to our refund policy</li>
            <li>Users are responsible for applicable taxes</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Intellectual Property</h2>
          <p className="text-gray-600">
            All content on the platform, including but not limited to text, graphics, logos, and software, is the property of Sigmaverse and protected by intellectual property laws.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Limitation of Liability</h2>
          <p className="text-gray-600">
            Sigmaverse shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use of our services.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Contact Information</h2>
          <p className="text-gray-600">
            For any questions regarding these Terms of Service, please contact us at:
          </p>
          <ul className="list-none pl-6 text-gray-600 space-y-2">
            <li>Email: legal@sigmaverse.in</li>
            <li>Phone: +91-8494955955</li>
            <li>Address: T-Hub, Plot No 1/C, Sy No 83/1, Raidurgam panmaktha Hyderabad Knowledge City</li>
          </ul>
        </section>
      </div>
    </div>
  );
}