import React from 'react';
import { Routes, Route } from 'react-router-dom';
import DigitalSubcategories from './DigitalSubcategories';
import DigitalInventory from './DigitalInventory';
import DigitalProduct from './DigitalProduct';

export default function DigitalCategory() {
  return (
    <Routes>
      <Route index element={<DigitalSubcategories />} />
      <Route path=":subcategory" element={<DigitalInventory />} />
      <Route path=":subcategory/:id" element={<DigitalProduct />} />
    </Routes>
  );
}