import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BTLSubcategories from './BTLSubcategories';
import BTLInventory from './BTLInventory';
import BTLProduct from './BTLProduct';

export default function BTLCategory() {
  return (
    <Routes>
      <Route index element={<BTLSubcategories />} />
      <Route path=":subcategory" element={<BTLInventory />} />
      <Route path=":subcategory/:id" element={<BTLProduct />} />
    </Routes>
  );
}