export const MOCK_AIRPORTS = [
  {
    id: 'del',
    name: 'Indira Gandhi International Airport',
    code: 'DEL',
    city: 'Delhi',
    footfall: '5Cr+ Annual',
    terminals: ['T1', 'T2', 'T3'],
    inventory: [
      {
        id: 'del-1',
        type: 'Digital Screen',
        location: 'T3 Departure',
        price: 250000,
        size: '10ft x 6ft',
        footfall: '50,000+ daily',
        availability: 'available',
        images: [
          'https://images.unsplash.com/photo-1583863788434-e58a36330cf0',
          'https://images.unsplash.com/photo-1583864697784-a0efc8379f70'
        ],
        features: [
          'High Visibility',
          'Premium Location',
          'High Dwell Time',
          'International Travelers'
        ]
      },
      {
        id: 'del-2',
        type: 'Baggage Belt Branding',
        location: 'T3 Arrival',
        price: 350000,
        size: 'Multiple Displays',
        footfall: '75,000+ daily',
        availability: 'available',
        images: [
          'https://images.unsplash.com/photo-1583864697784-a0efc8379f70',
          'https://images.unsplash.com/photo-1583863788434-e58a36330cf0'
        ],
        features: [
          'Captive Audience',
          'Long Exposure Time',
          'Multiple Touchpoints',
          'Brand Visibility'
        ]
      },
      {
        id: 'del-3',
        type: 'Pillar Wraps',
        location: 'T3 Check-in Area',
        price: 180000,
        size: '8ft x 4ft (x10 pillars)',
        footfall: '60,000+ daily',
        availability: 'available',
        images: [
          'https://images.unsplash.com/photo-1583864697784-a0efc8379f70',
          'https://images.unsplash.com/photo-1583863788434-e58a36330cf0'
        ],
        features: [
          'High Impact',
          'Multiple Locations',
          'Premium Environment',
          'Domestic & International'
        ]
      }
    ],
    features: ['International Terminal', 'Premium Audience', 'High Dwell Time'],
    image: 'https://images.unsplash.com/photo-1583863788434-e58a36330cf0'
  },
  {
    id: 'bom',
    name: 'Chhatrapati Shivaji International Airport',
    code: 'BOM',
    city: 'Mumbai',
    footfall: '4.5Cr+ Annual',
    terminals: ['T1', 'T2'],
    inventory: [
      {
        id: 'bom-1',
        type: 'LED Wall',
        location: 'T2 Security',
        price: 200000,
        size: '20ft x 10ft',
        footfall: '45,000+ daily',
        availability: 'available',
        images: [
          'https://images.unsplash.com/photo-1583864697784-a0efc8379f70',
          'https://images.unsplash.com/photo-1583863788434-e58a36330cf0'
        ],
        features: [
          'Digital Display',
          'Prime Location',
          'High Visibility',
          'Premium Audience'
        ]
      },
      {
        id: 'bom-2',
        type: 'Poster Display',
        location: 'T2 Check-in',
        price: 150000,
        size: '10 Pillars',
        footfall: '60,000+ daily',
        availability: 'available',
        images: [
          'https://images.unsplash.com/photo-1583864697784-a0efc8379f70',
          'https://images.unsplash.com/photo-1583863788434-e58a36330cf0'
        ],
        features: [
          'Strategic Placement',
          'High Traffic Area',
          'Brand Visibility',
          'Multiple Units'
        ]
      }
    ],
    features: ['Business Hub', 'International Travelers', 'Long Exposure'],
    image: 'https://images.unsplash.com/photo-1583864697784-a0efc8379f70'
  }
];