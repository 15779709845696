import React from 'react';
import { MessageSquare, User, Clock, CheckCircle } from 'lucide-react';

const mockTickets = [
  {
    id: '1',
    user: 'Rahul Sharma',
    subject: 'Campaign Approval Delay',
    status: 'open',
    priority: 'high',
    createdAt: '2024-03-15T10:30:00',
    lastUpdate: '2024-03-15T14:20:00',
  },
  {
    id: '2',
    user: 'Priya Patel',
    subject: 'Payment Integration Issue',
    status: 'in_progress',
    priority: 'medium',
    createdAt: '2024-03-14T15:45:00',
    lastUpdate: '2024-03-15T09:15:00',
  },
];

export default function Support() {
  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Support</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Tickets</option>
            <option>Open</option>
            <option>In Progress</option>
            <option>Resolved</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2">
          <div className="space-y-4">
            {mockTickets.map((ticket) => (
              <div key={ticket.id} className="bg-white rounded-lg shadow-sm p-6">
                <div className="flex items-center justify-between mb-4">
                  <div className="flex items-center">
                    <div className="h-10 w-10 rounded-full bg-purple-100 flex items-center justify-center">
                      <User className="h-5 w-5 text-purple-600" />
                    </div>
                    <div className="ml-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        {ticket.subject}
                      </h2>
                      <p className="text-sm text-gray-500">
                        by {ticket.user}
                      </p>
                    </div>
                  </div>
                  <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                    ticket.status === 'open'
                      ? 'bg-yellow-100 text-yellow-800'
                      : 'bg-blue-100 text-blue-800'
                  }`}>
                    {ticket.status === 'open' ? 'Open' : 'In Progress'}
                  </span>
                </div>

                <div className="flex items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center">
                    <Clock className="h-4 w-4 mr-1" />
                    {new Date(ticket.createdAt).toLocaleString()}
                  </div>
                  <div className="flex space-x-2">
                    <button className="btn-secondary">View Details</button>
                    <button className="btn-primary">Respond</button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div>
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Quick Stats</h2>
            <div className="space-y-4">
              {[
                { label: 'Open Tickets', value: '12' },
                { label: 'Average Response Time', value: '2.5 hours' },
                { label: 'Resolution Rate', value: '94%' },
              ].map((stat) => (
                <div key={stat.label} className="flex justify-between items-center">
                  <span className="text-sm text-gray-600">{stat.label}</span>
                  <span className="font-medium">{stat.value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Recent Activity</h2>
            <div className="space-y-4">
              {[1, 2, 3].map((item) => (
                <div key={item} className="flex items-start">
                  <CheckCircle className="h-5 w-5 text-green-500 mt-0.5" />
                  <div className="ml-3">
                    <p className="text-sm text-gray-900">Ticket #123 resolved</p>
                    <p className="text-xs text-gray-500">2 hours ago</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}