import React from 'react';
import { Routes, Route } from 'react-router-dom';
import OOHSubcategories from './OOHSubcategories';
import OOHInventory from './OOHInventory';
import OOHProduct from './OOHProduct';

export default function OOHCategory() {
  return (
    <Routes>
      <Route index element={<OOHSubcategories />} />
      <Route path=":subcategory" element={<OOHInventory />} />
      <Route path=":subcategory/:id" element={<OOHProduct />} />
    </Routes>
  );
}