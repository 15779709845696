import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Film, MapPin, Users, Clock } from 'lucide-react';

const mockTheatres = [
  {
    id: '1',
    name: 'PVR IMAX',
    location: 'Mumbai',
    screens: 6,
    adSpots: [
      { type: 'On-screen', price: 25000 },
      { type: 'Standee', price: 5000 },
      { type: 'Food Court', price: 8000 }
    ],
    averageFootfall: '2000+ daily',
    availability: 'Available'
  },
  // Add more mock data
];

export default function TheatreCategory() {
  const navigate = useNavigate();

  return (
    <div>
      <h1 className="text-2xl font-bold text-gray-900 mb-6">Theatre Advertising</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {mockTheatres.map((theatre) => (
          <div 
            key={theatre.id}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all cursor-pointer"
            onClick={() => navigate(`/category/theatre/${theatre.id}`)}
          >
            <div className="p-6">
              <div className="flex items-center justify-between mb-4">
                <h3 className="text-lg font-medium text-gray-900">{theatre.name}</h3>
                <span className="px-2 py-1 text-xs font-medium rounded-full bg-green-100 text-green-800">
                  {theatre.availability}
                </span>
              </div>

              <div className="space-y-2">
                <div className="flex items-center text-sm text-gray-600">
                  <MapPin className="h-4 w-4 mr-2" />
                  {theatre.location}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Film className="h-4 w-4 mr-2" />
                  {theatre.screens} Screens
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Users className="h-4 w-4 mr-2" />
                  {theatre.averageFootfall}
                </div>
              </div>

              <div className="mt-4 pt-4 border-t border-gray-200">
                <h4 className="text-sm font-medium text-gray-900 mb-2">Available Ad Spots</h4>
                <div className="space-y-1">
                  {theatre.adSpots.map((spot) => (
                    <div key={spot.type} className="flex justify-between text-sm">
                      <span className="text-gray-600">{spot.type}</span>
                      <span className="font-medium">₹{spot.price.toLocaleString()}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}