import React, { useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const testimonials = [
  {
    quote: "Simgaverse has been an invaluable partner throughout our campaign journey. Their unwavering support at every stage, from ideation to execution, has been remarkable. Their expertise, creativity, and attention to detail ensured a seamless and highly successful campaign.",
    author: "Shankar Mohanty",
    position: "Regional Marketing Manager",
    company: "Lloyd"
  },
  {
    quote: "Simgaverse has been the driving force behind our Gold loan campaign. Their unwavering support at every stage, from inception to execution, has been nothing short of exceptional. The Simgaverse team consistently exceeded our expectations, delivering outstanding results.",
    author: "Himanshu",
    position: "Regional Marketing Manager",
    company: "Kotak Mahindra Bank"
  },
  {
    quote: "From the moment we embarked on our campaign, Simgaverse became our guiding star. Their expertise and dedication were evident throughout the entire journey, from ideation to flawless execution. The team's unwavering commitment resulted in resounding success.",
    author: "Krishna",
    position: "AVP Marketing",
    company: "Jai Kisan"
  },
  {
    quote: "Working with Simgaverse has been a game-changer for our campaign success. Their unwavering support has been truly exceptional. We are incredibly grateful for their partnership and can confidently say that choosing Simgaverse was the best decision we made.",
    author: "Sidharth",
    position: "Marketing Head",
    company: "Trust Labs"
  }
];

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const next = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prev = () => {
    setCurrentIndex((prevIndex) => 
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-center text-3xl font-bold text-gray-900 mb-12">
          Client Testimonials
        </h2>
        
        <div className="relative max-w-4xl mx-auto">
          <button 
            onClick={prev}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-12 p-2 rounded-full bg-white shadow-lg hover:bg-gray-50"
          >
            <ChevronLeft className="h-6 w-6 text-gray-600" />
          </button>

          <div className="overflow-hidden">
            <div 
              className="transition-transform duration-500 ease-in-out"
              style={{ transform: `translateX(-${currentIndex * 100}%)` }}
            >
              <div className="flex">
                {testimonials.map((testimonial, index) => (
                  <div
                    key={index}
                    className="w-full flex-shrink-0 px-4"
                  >
                    <blockquote className="bg-white rounded-lg shadow-sm p-8">
                      <div className="text-2xl text-purple-600 mb-4">"</div>
                      <p className="text-lg text-gray-700 mb-6">
                        {testimonial.quote}
                      </p>
                      <div className="flex items-center">
                        <div className="h-12 w-12 rounded-full bg-purple-100 flex items-center justify-center">
                          <span className="text-xl font-semibold text-purple-600">
                            {testimonial.author[0]}
                          </span>
                        </div>
                        <div className="ml-4">
                          <p className="font-semibold text-gray-900">{testimonial.author}</p>
                          <p className="text-sm text-gray-600">{testimonial.position}</p>
                          <p className="text-sm font-medium text-purple-600">{testimonial.company}</p>
                        </div>
                      </div>
                    </blockquote>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <button 
            onClick={next}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-12 p-2 rounded-full bg-white shadow-lg hover:bg-gray-50"
          >
            <ChevronRight className="h-6 w-6 text-gray-600" />
          </button>

          <div className="flex justify-center mt-8 space-x-2">
            {testimonials.map((_, index) => (
              <button
                key={index}
                className={`h-2 w-2 rounded-full transition-colors ${
                  index === currentIndex ? 'bg-purple-600' : 'bg-gray-300'
                }`}
                onClick={() => setCurrentIndex(index)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}