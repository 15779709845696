export interface Influencer {
  id: string;
  name: string;
  categories: string[];
  languages: string[];
  handles: {
    instagram: string;
    youtube?: string;
    facebook?: string;
  };
  stats: {
    instagram: {
      followers: number;
      engagement: number;
    };
    youtube?: {
      subscribers: number;
      views: number;
    };
    engagement: number;
  };
  pricing: {
    type: string;
    amount: number;
  }[];
  coverImage: string;
  bio: string;
}

export const INFLUENCERS: Influencer[] = [
  {
    id: "1",
    name: "Komal Pandey",
    categories: ["Lifestyle", "Fashion"],
    languages: ["Hindi", "English"],
    handles: {
      instagram: "komalpandeyofficial",
      youtube: "KomalPandey"
    },
    stats: {
      instagram: {
        followers: 1800000,
        engagement: 6.5
      },
      youtube: {
        subscribers: 1200000,
        views: 150000000
      },
      engagement: 7.2
    },
    pricing: [
      { type: "Instagram Story", amount: 150000 },
      { type: "Instagram Reel", amount: 300000 },
      { type: "YouTube Video", amount: 800000 },
      { type: "Content Integration", amount: 400000 }
    ],
    coverImage: "https://images.unsplash.com/photo-1483985988355-763728e1935b",
    bio: "Fashion and lifestyle content creator with a focus on personal style and beauty."
  },
  {
    id: "2",
    name: "Technical Guruji",
    categories: ["Tech", "Education"],
    languages: ["Hindi", "English"],
    handles: {
      instagram: "technicalguruji",
      youtube: "TechnicalGuruji"
    },
    stats: {
      instagram: {
        followers: 5800000,
        engagement: 4.2
      },
      youtube: {
        subscribers: 23000000,
        views: 4000000000
      },
      engagement: 5.8
    },
    pricing: [
      { type: "Instagram Story", amount: 200000 },
      { type: "Instagram Reel", amount: 500000 },
      { type: "YouTube Integration", amount: 1500000 },
      { type: "Dedicated Video", amount: 2500000 }
    ],
    coverImage: "https://images.unsplash.com/photo-1531297484001-80022131f5a1",
    bio: "India's leading tech reviewer and digital educator."
  },
  {
    id: "3",
    name: "Kabita's Kitchen",
    categories: ["Food", "Lifestyle"],
    languages: ["Hindi", "English"],
    handles: {
      instagram: "kabitaskitchen",
      youtube: "KabitasKitchen"
    },
    stats: {
      instagram: {
        followers: 1200000,
        engagement: 5.8
      },
      youtube: {
        subscribers: 12000000,
        views: 2000000000
      },
      engagement: 6.5
    },
    pricing: [
      { type: "Recipe Integration", amount: 300000 },
      { type: "Instagram Reel", amount: 200000 },
      { type: "YouTube Video", amount: 800000 },
      { type: "Brand Collaboration", amount: 1000000 }
    ],
    coverImage: "https://images.unsplash.com/photo-1556910103-1c02745aae4d",
    bio: "Sharing easy and delicious recipes with millions of food lovers."
  },
  {
    id: "4",
    name: "Ankur Warikoo",
    categories: ["Business", "Education"],
    languages: ["English", "Hindi"],
    handles: {
      instagram: "warikoo",
      youtube: "AnkurWarikoo"
    },
    stats: {
      instagram: {
        followers: 2500000,
        engagement: 7.8
      },
      youtube: {
        subscribers: 2800000,
        views: 300000000
      },
      engagement: 8.2
    },
    pricing: [
      { type: "LinkedIn Post", amount: 250000 },
      { type: "Instagram Story", amount: 300000 },
      { type: "YouTube Integration", amount: 1000000 },
      { type: "Workshop/Webinar", amount: 2000000 }
    ],
    coverImage: "https://images.unsplash.com/photo-1553484771-371a605b060b",
    bio: "Entrepreneur, educator, and content creator focused on business and personal development."
  }
];