import React from 'react';
import { Routes, Route } from 'react-router-dom';

// TV Category
import TVCategory from '../pages/Category/TV';
import TVSubcategories from '../pages/Category/TV/TVSubcategories';
import TVInventory from '../pages/Category/TV/TVInventory';
import TVProduct from '../pages/Category/TV/TVProduct';

// Radio Category
import RadioCategory from '../pages/Category/Radio';
import RadioSubcategories from '../pages/Category/Radio/RadioSubcategories';
import RadioInventory from '../pages/Category/Radio/RadioInventory';
import RadioProduct from '../pages/Category/Radio/RadioProduct';

// Airport Category
import AirportCategory from '../pages/Category/Airport';
import AirportList from '../pages/Category/Airport/AirportList';
import AirportInventory from '../pages/Category/Airport/AirportInventory';
import AirportProduct from '../pages/Category/Airport/AirportProduct';

// Theatre Category
import TheatreCategory from '../pages/Category/Theatre';
import TheatreSubcategories from '../pages/Category/Theatre/TheatreSubcategories';
import TheatreInventory from '../pages/Category/Theatre/TheatreInventory';
import TheatreProduct from '../pages/Category/Theatre/TheatreProduct';

// Newspaper Category
import NewspaperCategory from '../pages/Category/Newspaper';
import NewspaperSubcategories from '../pages/Category/Newspaper/NewspaperSubcategories';
import NewspaperInventory from '../pages/Category/Newspaper/NewspaperInventory';
import NewspaperProduct from '../pages/Category/Newspaper/NewspaperProduct';

// Magazine Category
import MagazineCategory from '../pages/Category/Magazine';
import MagazineSubcategories from '../pages/Category/Magazine/MagazineSubcategories';
import MagazineInventory from '../pages/Category/Magazine/MagazineInventory';
import MagazineProduct from '../pages/Category/Magazine/MagazineProduct';

// BTL Category
import BTLCategory from '../pages/Category/BTL';
import BTLSubcategories from '../pages/Category/BTL/BTLSubcategories';
import BTLInventory from '../pages/Category/BTL/BTLInventory';
import BTLProduct from '../pages/Category/BTL/BTLProduct';

// Events Category
import EventsCategory from '../pages/Category/Events';
import EventsSubcategories from '../pages/Category/Events/EventsSubcategories';
import EventsInventory from '../pages/Category/Events/EventsInventory';
import EventsProduct from '../pages/Category/Events/EventsProduct';

// Vehicle Category
import VehicleCategory from '../pages/Category/Vehicle';
import VehicleSubcategories from '../pages/Category/Vehicle/VehicleSubcategories';
import VehicleInventory from '../pages/Category/Vehicle/VehicleInventory';
import VehicleProduct from '../pages/Category/Vehicle/VehicleProduct';

// Influencer Category
import InfluencerCategory from '../pages/Category/Influencer';
import InfluencerSubcategories from '../pages/Category/Influencer/InfluencerSubcategories';
import InfluencerInventory from '../pages/Category/Influencer/InfluencerInventory';
import InfluencerProfilePage from '../pages/Category/Influencer/InfluencerProfilePage';

// Digital Category
import DigitalCategory from '../pages/Category/Digital';
import DigitalSubcategories from '../pages/Category/Digital/DigitalSubcategories';
import DigitalInventory from '../pages/Category/Digital/DigitalInventory';
import DigitalProduct from '../pages/Category/Digital/DigitalProduct';

// OOH Category
import OOHCategory from '../pages/Category/OOH';
import OOHSubcategories from '../pages/Category/OOH/OOHSubcategories';
import OOHInventory from '../pages/Category/OOH/OOHInventory';
import OOHProduct from '../pages/Category/OOH/OOHProduct';

export default function CategoryRoutes() {
  return (
    <Routes>
      {/* TV Routes */}
      <Route path="tv" element={<TVCategory />}>
        <Route index element={<TVSubcategories />} />
        <Route path=":channelId" element={<TVInventory />} />
        <Route path=":channelId/:inventoryId" element={<TVProduct />} />
      </Route>

      {/* Radio Routes */}
      <Route path="radio" element={<RadioCategory />}>
        <Route index element={<RadioSubcategories />} />
        <Route path=":stationId" element={<RadioInventory />} />
        <Route path=":stationId/:inventoryId" element={<RadioProduct />} />
      </Route>

      {/* Airport Routes */}
      <Route path="airport" element={<AirportCategory />}>
        <Route index element={<AirportList />} />
        <Route path=":airportId" element={<AirportInventory />} />
        <Route path=":airportId/:inventoryId" element={<AirportProduct />} />
      </Route>

      {/* Theatre Routes */}
      <Route path="theatre" element={<TheatreCategory />}>
        <Route index element={<TheatreSubcategories />} />
        <Route path=":subcategory" element={<TheatreInventory />} />
        <Route path=":subcategory/:id" element={<TheatreProduct />} />
      </Route>

      {/* Newspaper Routes */}
      <Route path="newspaper" element={<NewspaperCategory />}>
        <Route index element={<NewspaperSubcategories />} />
        <Route path=":subcategory" element={<NewspaperInventory />} />
        <Route path=":subcategory/:id" element={<NewspaperProduct />} />
      </Route>

      {/* Magazine Routes */}
      <Route path="magazine" element={<MagazineCategory />}>
        <Route index element={<MagazineSubcategories />} />
        <Route path=":subcategory" element={<MagazineInventory />} />
        <Route path=":subcategory/:id" element={<MagazineProduct />} />
      </Route>

      {/* BTL Routes */}
      <Route path="btl" element={<BTLCategory />}>
        <Route index element={<BTLSubcategories />} />
        <Route path=":subcategory" element={<BTLInventory />} />
        <Route path=":subcategory/:id" element={<BTLProduct />} />
      </Route>

      {/* Events Routes */}
      <Route path="events" element={<EventsCategory />}>
        <Route index element={<EventsSubcategories />} />
        <Route path=":subcategory" element={<EventsInventory />} />
        <Route path=":subcategory/:id" element={<EventsProduct />} />
      </Route>

      {/* Vehicle Routes */}
      <Route path="vehicle" element={<VehicleCategory />}>
        <Route index element={<VehicleSubcategories />} />
        <Route path=":subcategory" element={<VehicleInventory />} />
        <Route path=":subcategory/:id" element={<VehicleProduct />} />
      </Route>

      {/* Influencer Routes */}
      <Route path="influencer" element={<InfluencerCategory />}>
        <Route index element={<InfluencerSubcategories />} />
        <Route path=":subcategory" element={<InfluencerInventory />} />
        <Route path=":subcategory/:id" element={<InfluencerProfilePage />} />
      </Route>

      {/* Digital Routes */}
      <Route path="digital" element={<DigitalCategory />}>
        <Route index element={<DigitalSubcategories />} />
        <Route path=":subcategory" element={<DigitalInventory />} />
        <Route path=":subcategory/:id" element={<DigitalProduct />} />
      </Route>

      {/* OOH Routes */}
      <Route path="ooh" element={<OOHCategory />}>
        <Route index element={<OOHSubcategories />} />
        <Route path=":subcategory" element={<OOHInventory />} />
        <Route path=":subcategory/:id" element={<OOHProduct />} />
      </Route>
    </Routes>
  );
}