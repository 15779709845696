export const MOCK_BTL = [
  {
    id: 'mall-1',
    title: 'Phoenix MarketCity Mall Activation',
    type: 'mall_activation',
    location: {
      city: 'Mumbai',
      area: 'Kurla',
      venue: 'Phoenix MarketCity'
    },
    specifications: {
      area: '1000 sq ft',
      footfall: '50,000+ daily',
      timing: '11 AM - 9 PM',
      setup: 'Stage and Sound System Available'
    },
    pricing: {
      basePrice: 75000,
      unit: 'per_day',
      minimumDuration: 3
    },
    features: [
      'High Footfall Area',
      'Premium Audience',
      'Event Support',
      'Power Backup'
    ],
    images: [
      'https://images.unsplash.com/photo-1519389950473-47ba0277781c',
      'https://images.unsplash.com/photo-1519389950473-47ba0277781d'
    ],
    availability: {
      status: 'available',
      nextAvailable: '2024-03-20'
    }
  },
  {
    id: 'apt-1',
    title: 'Luxury Apartment Complex Activation',
    type: 'apartment_activation',
    location: {
      city: 'Bangalore',
      area: 'Whitefield',
      venue: 'Prestige Lakeside Habitat'
    },
    specifications: {
      societies: '15 towers',
      apartments: '2800+ units',
      residents: '10,000+',
      setup: 'Clubhouse and Common Areas'
    },
    pricing: {
      basePrice: 45000,
      unit: 'per_day',
      minimumDuration: 2
    },
    features: [
      'High-Income Audience',
      'Gated Community',
      'Multiple Touchpoints',
      'Weekend Activities'
    ],
    images: [
      'https://images.unsplash.com/photo-1545324418-cc1a3fa10c00',
      'https://images.unsplash.com/photo-1545324418-cc1a3fa10c01'
    ],
    availability: {
      status: 'available',
      nextAvailable: '2024-03-15'
    }
  },
  {
    id: 'corp-1',
    title: 'Tech Park Corporate Engagement',
    type: 'corporate_activation',
    location: {
      city: 'Hyderabad',
      area: 'HITEC City',
      venue: 'Mindspace Business Park'
    },
    specifications: {
      companies: '50+ companies',
      employees: '25,000+ professionals',
      timing: '9 AM - 6 PM',
      setup: 'Food Court and Common Areas'
    },
    pricing: {
      basePrice: 60000,
      unit: 'per_day',
      minimumDuration: 3
    },
    features: [
      'Professional Audience',
      'Multiple Locations',
      'IT Companies',
      'Weekday Activity'
    ],
    images: [
      'https://images.unsplash.com/photo-1497366216548-37526070297c',
      'https://images.unsplash.com/photo-1497366216548-37526070297d'
    ],
    availability: {
      status: 'available',
      nextAvailable: '2024-03-18'
    }
  }
];

export const BTL_CATEGORIES = [
  {
    id: 'mall_activation',
    name: 'Mall Activations',
    description: 'Engage with premium shoppers through mall activities',
    icon: 'ShoppingBag'
  },
  {
    id: 'apartment_activation',
    name: 'Apartment Activations',
    description: 'Connect with residential communities',
    icon: 'Home'
  },
  {
    id: 'corporate_activation',
    name: 'Corporate Engagements',
    description: 'Reach professionals in business environments',
    icon: 'Briefcase'
  },
  {
    id: 'college_activation',
    name: 'College Activations',
    description: 'Engage with young audience in educational institutions',
    icon: 'GraduationCap'
  }
];