import React from 'react';
import { Outlet } from 'react-router-dom';
import CategoryNav from '../../components/category/CategoryNav';

export default function CategoryLayout() {
  return (
    <div className="min-h-screen bg-gray-50 pt-16">
      <CategoryNav />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Outlet />
      </div>
    </div>
  );
}