import React from 'react';

export default function Disclaimer() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Disclaimer</h1>
      
      <div className="prose prose-lg">
        <p className="text-gray-600 mb-6">Last updated: March 15, 2024</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. General Information</h2>
          <p className="text-gray-600">
            The information provided on Sigmaverse is for general informational purposes only. While we strive to keep the information up to date and accurate, we make no representations or warranties of any kind about the completeness, accuracy, reliability, suitability, or availability of the platform.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. No Professional Advice</h2>
          <p className="text-gray-600">
            The information on this platform should not be considered as professional advice. Users should consult with appropriate professionals for specific advice tailored to their situation.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. Third-Party Content</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>We do not warrant the accuracy of third-party content</li>
            <li>Links to external sites are provided for convenience only</li>
            <li>We are not responsible for third-party websites</li>
            <li>Users access third-party content at their own risk</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Limitation of Liability</h2>
          <p className="text-gray-600">
            In no event shall Sigmaverse be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of the use or inability to use our platform.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Accuracy of Information</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>All information is provided "as is" without warranty</li>
            <li>We do not guarantee the accuracy of pricing information</li>
            <li>Availability of inventory may vary</li>
            <li>Campaign performance metrics are estimates</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Changes and Updates</h2>
          <p className="text-gray-600">
            We reserve the right to modify or replace this disclaimer at any time. Users will be notified of any material changes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Contact Information</h2>
          <p className="text-gray-600">
            For questions about this disclaimer, please contact us at:
          </p>
          <ul className="list-none pl-6 text-gray-600 space-y-2">
            <li>Email: legal@sigmaverse.in</li>
            <li>Phone: +91-8494955955</li>
          </ul>
        </section>
      </div>
    </div>
  );
}