import React, { useState } from 'react';
import { Plus, Calendar } from 'lucide-react';
import AddToCampaignModal from './AddToCampaignModal';
import AddToCampaignButton from './AddToCampaignButton';

interface ProductActionsProps {
  inventory: {
    id: string;
    type: string;
    location: string;
    price: number;
    mediaOwner: {
      id: string;
      name: string;
    };
  };
}

export default function ProductActions({ inventory }: ProductActionsProps) {
  const [showAddToCampaign, setShowAddToCampaign] = useState(false);

  return (
    <div className="space-y-4">
      <AddToCampaignButton
        onAdd={() => setShowAddToCampaign(true)}
        className="w-full"
      />
      
      <div className="bg-gray-50 rounded-lg p-4">
        <div className="flex items-center text-sm text-gray-600">
          <Calendar className="h-4 w-4 mr-2" />
          <span>Minimum booking duration: 1 month</span>
        </div>
      </div>

      {showAddToCampaign && (
        <AddToCampaignModal
          inventory={inventory}
          onClose={() => setShowAddToCampaign(false)}
          onAdd={(campaignId, details) => {
            console.log('Adding to campaign:', campaignId, details);
            setShowAddToCampaign(false);
          }}
          existingCampaigns={[]}
        />
      )}
    </div>
  );
}