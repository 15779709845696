import React from 'react';
import { Link } from 'react-router-dom';
import { Trophy, Building, Music, Users } from 'lucide-react';
import { MOCK_EVENTS, EVENT_CATEGORIES } from '../../../data/mockEvents';

export default function EventsSubcategories() {
  // Count events in each category
  const eventCounts = EVENT_CATEGORIES.reduce((acc, category) => {
    acc[category.id] = MOCK_EVENTS.filter(event => event.type === category.id).length;
    return acc;
  }, {} as Record<string, number>);

  const getIcon = (iconName: string) => {
    switch (iconName) {
      case 'Trophy':
        return Trophy;
      case 'Building':
        return Building;
      case 'Music':
        return Music;
      case 'Users':
        return Users;
      default:
        return Building;
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Events & Sponsorships</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Cities</option>
            <option>Mumbai</option>
            <option>Delhi</option>
            <option>Bangalore</option>
            <option>Goa</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {EVENT_CATEGORIES.map((category) => {
          const Icon = getIcon(category.icon);
          const eventCount = eventCounts[category.id] || 0;
          const events = MOCK_EVENTS.filter(event => event.type === category.id);
          
          return (
            <Link
              key={category.id}
              to={category.id}
              className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
            >
              <div className="flex items-start justify-between">
                <div className="flex-1">
                  <div className="flex items-center">
                    <Icon className="h-6 w-6 text-purple-600 mr-2" />
                    <h2 className="text-lg font-medium text-gray-900">
                      {category.name}
                    </h2>
                  </div>
                  <p className="mt-2 text-sm text-gray-600">
                    {category.description}
                  </p>
                </div>
                <span className="text-sm text-gray-500">
                  {eventCount} events
                </span>
              </div>

              {events.length > 0 && (
                <div className="mt-4 space-y-3">
                  <h3 className="text-sm font-medium text-gray-700">Upcoming Events:</h3>
                  {events.slice(0, 2).map(event => (
                    <div key={event.id} className="text-sm text-gray-600 flex justify-between items-center">
                      <span>{event.title}</span>
                      <span className="text-xs text-purple-600">
                        From ₹{Math.min(...event.sponsorshipOptions.map(opt => opt.price)).toLocaleString()}
                      </span>
                    </div>
                  ))}
                </div>
              )}

              <div className="mt-4 flex flex-wrap gap-2">
                {events[0]?.features.slice(0, 3).map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}