import React from 'react';
import { motion } from 'framer-motion';
import { Package, BarChart2, DollarSign, Users, MessageSquare } from 'lucide-react';

export default function MediaOwnerSolutions() {
  const openWhatsApp = () => {
    window.open('https://wa.me/918546911911', '_blank');
  };

  return (
    <div className="pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-16">
          <motion.h1 
            className="text-4xl font-bold text-gray-900 mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Solutions for Media Owners
          </motion.h1>
          <motion.p 
            className="text-xl text-gray-600 max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Maximize your inventory revenue with our comprehensive platform
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {[
            {
              title: "Inventory Management",
              description: "Easily manage and update your media inventory",
              icon: Package,
              color: "bg-purple-100 text-purple-600"
            },
            {
              title: "Dynamic Pricing",
              description: "Optimize revenue with smart pricing rules",
              icon: DollarSign,
              color: "bg-green-100 text-green-600"
            },
            {
              title: "Performance Analytics",
              description: "Track occupancy and revenue metrics",
              icon: BarChart2,
              color: "bg-blue-100 text-blue-600"
            }
          ].map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-lg shadow-sm p-6"
            >
              <div className={`rounded-full w-12 h-12 ${feature.color} flex items-center justify-center mb-4`}>
                <feature.icon className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="bg-green-50 rounded-lg p-8 mb-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-2xl font-bold text-green-900 mb-4">
              Ready to List Your Inventory?
            </h2>
            <p className="text-green-700 mb-8">
              Join India's largest advertising marketplace and connect with premium advertisers.
            </p>
            <div className="flex justify-center space-x-4">
              <button className="btn-primary">
                Register Now
              </button>
              <button 
                onClick={openWhatsApp}
                className="btn-secondary flex items-center"
              >
                <MessageSquare className="mr-2 h-5 w-5" />
                Chat with Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}