import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ParallaxProvider } from 'react-scroll-parallax';
import { Brain, Target, Users, DollarSign, ArrowRight, MessageSquare, X } from 'lucide-react';
import CategoryGrid from '../components/CategoryGrid';
import Features from '../components/home/Features';
import Stats from '../components/home/Stats';
import ClientLogos from '../components/home/ClientLogos';
import Testimonials from '../components/home/Testimonials';
import FAQ from '../components/home/FAQ';
import QuickCampaign from '../components/ai/QuickCampaign';

export default function Home() {
  const [showQuickCampaign, setShowQuickCampaign] = useState(false);

  const openWhatsApp = () => {
    window.open('https://wa.me/918494955955', '_blank');
  };

  return (
    <ParallaxProvider>
      {/* Hero Section */}
      <section className="relative min-h-[80vh] bg-gradient-to-br from-purple-600 to-indigo-800">
        <div className="absolute inset-0">
          <img 
            src="https://images.unsplash.com/photo-1557804506-669a67965ba0"
            alt="Background"
            className="w-full h-full object-cover opacity-10"
          />
        </div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-32 pb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="text-center"
          >
            <h1 className="text-4xl md:text-5xl lg:text-6xl font-bold text-white mb-6">
              Transform Your Advertising
            </h1>
            <p className="text-xl text-purple-100 mb-8 max-w-3xl mx-auto">
              India's first integrated advertising marketplace connecting brands with premium media opportunities.
            </p>
            <div className="flex justify-center space-x-4">
              <button 
                onClick={() => setShowQuickCampaign(true)}
                className="btn-primary inline-flex items-center"
              >
                <Brain className="mr-2 h-5 w-5" />
                Try AI Planner
              </button>
              <Link 
                to="/category"
                className="btn-secondary inline-flex items-center"
              >
                Explore Media <ArrowRight className="ml-2 h-4 w-4" />
              </Link>
              <button 
                onClick={openWhatsApp}
                className="btn-secondary inline-flex items-center"
              >
                <MessageSquare className="mr-2 h-4 w-4" />
                Chat with Us
              </button>
            </div>
          </motion.div>
        </div>
      </section>

      {/* Features Section */}
      <Features />

      {/* Categories Grid */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900">Discover Media Options</h2>
            <p className="mt-4 text-lg text-gray-600">
              Explore our comprehensive range of advertising solutions
            </p>
          </div>
          <CategoryGrid />
        </div>
      </section>

      {/* Stats Section */}
      <Stats />

      {/* Client Logos */}
      <ClientLogos />

      {/* Testimonials */}
      <Testimonials />

      {/* FAQ Section */}
      <FAQ />

      {/* Quick Campaign Modal */}
      {showQuickCampaign && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg max-w-4xl w-full mx-4 max-h-[90vh] overflow-y-auto relative">
            <button
              onClick={() => setShowQuickCampaign(false)}
              className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-500 rounded-full hover:bg-gray-100"
            >
              <X className="h-6 w-6" />
            </button>
            <QuickCampaign onClose={() => setShowQuickCampaign(false)} />
          </div>
        </div>
      )}
    </ParallaxProvider>
  );
}