import React, { useState } from 'react';
import { Save, User, Bell, Shield, Globe, CreditCard } from 'lucide-react';

export default function Settings() {
  const [settings, setSettings] = useState({
    platformName: 'Sigmaverse',
    supportEmail: 'support@sigmaverse.in',
    currency: 'INR',
    twoFactorEnabled: false,
    loginNotifications: true
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleToggle = (name: string) => {
    setSettings(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle settings update
    console.log('Settings updated:', settings);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Settings</h1>
        <button 
          onClick={handleSubmit}
          className="btn-primary flex items-center"
        >
          <Save className="h-4 w-4 mr-2" />
          Save Changes
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-6">General Settings</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Platform Name
                </label>
                <input
                  type="text"
                  name="platformName"
                  className="mt-1 input"
                  value={settings.platformName}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Support Email
                </label>
                <input
                  type="email"
                  name="supportEmail"
                  className="mt-1 input"
                  value={settings.supportEmail}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Default Currency
                </label>
                <select
                  name="currency"
                  className="mt-1 input"
                  value={settings.currency}
                  onChange={handleChange}
                >
                  <option value="INR">INR (₹)</option>
                  <option value="USD">USD ($)</option>
                  <option value="EUR">EUR (€)</option>
                </select>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-6">Security Settings</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-gray-900">Two-Factor Authentication</p>
                  <p className="text-sm text-gray-500">Add an extra layer of security</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={settings.twoFactorEnabled}
                    onChange={() => handleToggle('twoFactorEnabled')}
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div>
                  <p className="font-medium text-gray-900">Login Notifications</p>
                  <p className="text-sm text-gray-500">Get notified of new sign-ins</p>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    className="toggle"
                    checked={settings.loginNotifications}
                    onChange={() => handleToggle('loginNotifications')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Quick Settings</h2>
            <nav className="space-y-2">
              {[
                { name: 'Profile', icon: User },
                { name: 'Notifications', icon: Bell },
                { name: 'Security', icon: Shield },
                { name: 'Localization', icon: Globe },
                { name: 'Billing', icon: CreditCard },
              ].map((item) => (
                <button
                  key={item.name}
                  className="flex items-center w-full px-3 py-2 text-sm font-medium text-gray-900 rounded-md hover:bg-gray-50"
                >
                  <item.icon className="h-5 w-5 text-gray-400 mr-3" />
                  {item.name}
                </button>
              ))}
            </nav>
          </div>

          <div className="bg-purple-50 rounded-lg p-6">
            <h3 className="text-sm font-medium text-purple-900 mb-2">Need Help?</h3>
            <p className="text-sm text-purple-700">
              Check our documentation or contact support for assistance.
            </p>
            <button className="mt-4 w-full btn-primary">
              View Documentation
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}