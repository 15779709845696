import React from 'react';
import { Link } from 'react-router-dom';
import { Users, Camera, Utensils, Briefcase, Heart } from 'lucide-react';
import { INFLUENCERS } from '../../../data/mockInfluencers';

const subcategories = [
  {
    id: 'lifestyle',
    name: 'Lifestyle & Fashion',
    icon: Heart,
    description: 'Fashion, beauty, and lifestyle influencers',
    count: INFLUENCERS.filter(i => i.categories.includes('Lifestyle')).length,
    features: ['High Engagement', 'Premium Audience', 'Brand Integration']
  },
  {
    id: 'tech',
    name: 'Tech & Gaming',
    icon: Camera,
    description: 'Technology reviewers and gaming creators',
    count: INFLUENCERS.filter(i => i.categories.includes('Tech')).length,
    features: ['Tech-Savvy Audience', 'Product Reviews', 'Unboxing Videos']
  },
  {
    id: 'food',
    name: 'Food & Culinary',
    icon: Utensils,
    description: 'Food bloggers and culinary experts',
    count: INFLUENCERS.filter(i => i.categories.includes('Food')).length,
    features: ['Recipe Integration', 'Restaurant Reviews', 'Cooking Shows']
  },
  {
    id: 'business',
    name: 'Business & Finance',
    icon: Briefcase,
    description: 'Business leaders and finance experts',
    count: INFLUENCERS.filter(i => i.categories.includes('Finance')).length,
    features: ['Professional Audience', 'Educational Content', 'Investment Tips']
  }
];

export default function InfluencerSubcategories() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Influencer Marketing</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Languages</option>
            <option>Hindi</option>
            <option>English</option>
            <option>Regional</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {subcategories.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={subcategory.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center">
                  <subcategory.icon className="h-6 w-6 text-purple-600 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">
                    {subcategory.name}
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  {subcategory.description}
                </p>
              </div>
              <span className="text-sm text-gray-500">
                {subcategory.count} creators
              </span>
            </div>

            <div className="mt-4 flex flex-wrap gap-2">
              {subcategory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}