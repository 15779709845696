import React from 'react';
import { Link } from 'react-router-dom';
import { Globe, Youtube, Facebook, MonitorPlay } from 'lucide-react';
import { DIGITAL_CATEGORIES } from '../../../data/mockDigital';

export default function DigitalSubcategories() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Digital Advertising</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Platforms</option>
            <option>Social Media</option>
            <option>Video</option>
            <option>Display</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {DIGITAL_CATEGORIES.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={subcategory.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center">
                  {subcategory.icon === 'Youtube' && <Youtube className="h-6 w-6 text-purple-600 mr-2" />}
                  {subcategory.icon === 'Globe' && <Globe className="h-6 w-6 text-purple-600 mr-2" />}
                  {subcategory.icon === 'Tv' && <MonitorPlay className="h-6 w-6 text-purple-600 mr-2" />}
                  {subcategory.icon === 'Share2' && <Facebook className="h-6 w-6 text-purple-600 mr-2" />}
                  <h2 className="text-lg font-medium text-gray-900">
                    {subcategory.name}
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  {subcategory.description}
                </p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}