import React from 'react';
import { DollarSign, Users } from 'lucide-react';

interface ProductMetricsProps {
  price: number;
  priceUnit?: string;
  viewership: string;
  viewershipLabel?: string;
}

export default function ProductMetrics({
  price,
  priceUnit = '/month',
  viewership,
  viewershipLabel = 'Daily Footfall'
}: ProductMetricsProps) {
  return (
    <div className="grid grid-cols-2 gap-4 mb-6">
      <div className="bg-gray-50 rounded-lg p-4">
        <div className="flex items-center text-sm text-gray-600 mb-1">
          <DollarSign className="h-4 w-4 mr-2" />
          Price
        </div>
        <p className="text-lg font-semibold">
          ₹{price.toLocaleString()}
          <span className="text-sm text-gray-500">{priceUnit}</span>
        </p>
      </div>
      <div className="bg-gray-50 rounded-lg p-4">
        <div className="flex items-center text-sm text-gray-600 mb-1">
          <Users className="h-4 w-4 mr-2" />
          {viewershipLabel}
        </div>
        <p className="text-lg font-semibold">
          {viewership}
        </p>
      </div>
    </div>
  );
}