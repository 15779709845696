import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  Tv, 
  Radio, 
  Plane, 
  Film, 
  Newspaper, 
  Book, 
  ShoppingBag, 
  Calendar,
  Bus,
  Users,
  Globe,
  MapPin,
  MonitorPlay
} from 'lucide-react';

const categories = [
  { name: 'Television', icon: Tv, path: '/category/tv' },
  { name: 'Radio', icon: Radio, path: '/category/radio' },
  { name: 'Airport', icon: Plane, path: '/category/airport' },
  { name: 'Theatre', icon: Film, path: '/category/theatre' },
  { name: 'Newspaper', icon: Newspaper, path: '/category/newspaper' },
  { name: 'Magazine', icon: Book, path: '/category/magazine' },
  { name: 'BTL', icon: ShoppingBag, path: '/category/btl' },
  { name: 'Events', icon: Calendar, path: '/category/events' },
  { name: 'Transit Media', icon: Bus, path: '/category/transit' },
  { name: 'Influencer', icon: Users, path: '/category/influencer' },
  { name: 'Digital', icon: Globe, path: '/category/digital' },
  { name: 'OOH', icon: MapPin, path: '/category/ooh' },
  { name: 'DOOH', icon: MonitorPlay, path: '/category/dooh' }
];

export default function CategoryNav() {
  const location = useLocation();

  return (
    <nav className="bg-white border-b border-gray-200 sticky top-16 z-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex space-x-8 h-16 overflow-x-auto no-scrollbar">
          {categories.map((category) => {
            const isActive = location.pathname.startsWith(category.path);
            return (
              <Link
                key={category.name}
                to={category.path}
                className={`inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium whitespace-nowrap ${
                  isActive
                    ? 'border-purple-500 text-gray-900'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
                }`}
              >
                <category.icon className="h-4 w-4 mr-2" />
                {category.name}
              </Link>
            );
          })}
        </div>
      </div>
    </nav>
  );
}