import React, { useState } from 'react';
import { FileText, Calendar, DollarSign, Users } from 'lucide-react';
import { MOCK_CAMPAIGNS } from '../../data/mockCampaigns';

export default function Campaigns() {
  const [filter, setFilter] = useState('all');

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-gray-900">Campaigns</h1>
        <div className="flex items-center space-x-4">
          <select 
            className="input"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          >
            <option value="all">All Campaigns</option>
            <option value="active">Active</option>
            <option value="planned">Planned</option>
            <option value="completed">Completed</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 gap-6">
        {MOCK_CAMPAIGNS
          .filter(campaign => filter === 'all' || campaign.status === filter)
          .map((campaign) => (
            <div key={campaign.id} className="bg-white shadow-sm rounded-lg p-6">
              <div className="flex items-center justify-between mb-4">
                <div>
                  <h2 className="text-lg font-medium text-gray-900">{campaign.name}</h2>
                  <p className="text-sm text-gray-500">{campaign.client}</p>
                </div>
                <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                  campaign.status === 'active'
                    ? 'bg-green-100 text-green-800'
                    : campaign.status === 'planned'
                    ? 'bg-yellow-100 text-yellow-800'
                    : 'bg-gray-100 text-gray-800'
                }`}>
                  {campaign.status.charAt(0).toUpperCase() + campaign.status.slice(1)}
                </span>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
                <div className="flex items-center">
                  <DollarSign className="h-5 w-5 text-gray-400 mr-2" />
                  <div>
                    <p className="text-sm text-gray-500">Budget</p>
                    <p className="font-medium">₹{campaign.budget.toLocaleString()}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Users className="h-5 w-5 text-gray-400 mr-2" />
                  <div>
                    <p className="text-sm text-gray-500">Reach</p>
                    <p className="font-medium">{campaign.reach}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <Calendar className="h-5 w-5 text-gray-400 mr-2" />
                  <div>
                    <p className="text-sm text-gray-500">Start Date</p>
                    <p className="font-medium">{new Date(campaign.startDate).toLocaleDateString()}</p>
                  </div>
                </div>
                <div className="flex items-center">
                  <FileText className="h-5 w-5 text-gray-400 mr-2" />
                  <div>
                    <p className="text-sm text-gray-500">Channels</p>
                    <p className="font-medium">{campaign.channels.length}</p>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-end space-x-2">
                <button className="btn-secondary">View Details</button>
                <button className="btn-primary">Manage Campaign</button>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}