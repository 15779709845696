import React, { useState } from 'react';
import { Plus, Edit, Trash, Layout, Settings } from 'lucide-react';
import useCategoryStore from '../../hooks/useCategoryStore';

interface CategoryField {
  name: string;
  type: 'text' | 'number' | 'select' | 'boolean';
  required: boolean;
  options?: string[];
}

export default function CategoryManagement() {
  const {
    categories,
    loading,
    addCategory,
    updateCategory,
    deleteCategory
  } = useCategoryStore();

  const [showEditor, setShowEditor] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const [newFields, setNewFields] = useState<CategoryField[]>([]);

  const handleAddField = () => {
    setNewFields([...newFields, {
      name: '',
      type: 'text',
      required: false
    }]);
  };

  const handleFieldChange = (index: number, field: Partial<CategoryField>) => {
    const updatedFields = [...newFields];
    updatedFields[index] = { ...updatedFields[index], ...field };
    setNewFields(updatedFields);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Category Management</h1>
        <button
          onClick={() => setShowEditor(true)}
          className="btn-primary flex items-center"
        >
          <Plus className="h-4 w-4 mr-2" />
          Add Category
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {categories.map((category) => (
          <div key={category.id} className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center">
                <Layout className="h-5 w-5 text-purple-600 mr-2" />
                <h3 className="text-lg font-medium text-gray-900">
                  {category.name}
                </h3>
              </div>
              <div className="flex space-x-2">
                <button
                  onClick={() => {
                    setSelectedCategory(category);
                    setShowEditor(true);
                  }}
                  className="p-1 text-gray-400 hover:text-gray-500"
                >
                  <Edit className="h-5 w-5" />
                </button>
                <button
                  onClick={() => deleteCategory(category.id)}
                  className="p-1 text-gray-400 hover:text-red-500"
                >
                  <Trash className="h-5 w-5" />
                </button>
              </div>
            </div>

            <div className="space-y-4">
              <div>
                <h4 className="text-sm font-medium text-gray-700">Subcategories</h4>
                <div className="mt-1 space-y-1">
                  {category.subcategories.map((sub) => (
                    <div key={sub.id} className="flex items-center justify-between">
                      <span className="text-sm text-gray-600">{sub.name}</span>
                      <span className="text-xs text-gray-500">
                        {sub.inventoryCount} items
                      </span>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <h4 className="text-sm font-medium text-gray-700">Custom Fields</h4>
                <div className="mt-1 space-y-1">
                  {category.fields.map((field) => (
                    <div key={field.name} className="text-sm text-gray-600">
                      {field.name} ({field.type})
                      {field.required && ' *'}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {showEditor && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg max-w-2xl w-full p-6">
            <div className="flex items-center justify-between mb-6">
              <h2 className="text-xl font-semibold text-gray-900">
                {selectedCategory ? 'Edit Category' : 'Add Category'}
              </h2>
              <button onClick={() => setShowEditor(false)}>
                <Trash className="h-6 w-6 text-gray-400" />
              </button>
            </div>

            <form className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Category Name
                </label>
                <input type="text" className="mt-1 input" />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Icon
                </label>
                <select className="mt-1 input">
                  <option>Select Icon</option>
                </select>
              </div>

              <div>
                <div className="flex items-center justify-between mb-2">
                  <h3 className="text-sm font-medium text-gray-700">
                    Custom Fields
                  </h3>
                  <button
                    type="button"
                    onClick={handleAddField}
                    className="text-sm text-purple-600 hover:text-purple-500"
                  >
                    Add Field
                  </button>
                </div>

                <div className="space-y-4">
                  {newFields.map((field, index) => (
                    <div key={index} className="flex items-center space-x-4">
                      <input
                        type="text"
                        placeholder="Field name"
                        className="input flex-1"
                        value={field.name}
                        onChange={(e) => handleFieldChange(index, { name: e.target.value })}
                      />
                      <select
                        className="input w-32"
                        value={field.type}
                        onChange={(e) => handleFieldChange(index, { type: e.target.value as any })}
                      >
                        <option value="text">Text</option>
                        <option value="number">Number</option>
                        <option value="select">Select</option>
                        <option value="boolean">Boolean</option>
                      </select>
                      <label className="flex items-center">
                        <input
                          type="checkbox"
                          checked={field.required}
                          onChange={(e) => handleFieldChange(index, { required: e.target.checked })}
                          className="mr-2"
                        />
                        Required
                      </label>
                      <button
                        type="button"
                        onClick={() => {
                          const updated = [...newFields];
                          updated.splice(index, 1);
                          setNewFields(updated);
                        }}
                        className="text-red-600 hover:text-red-500"
                      >
                        <Trash className="h-5 w-5" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-end space-x-3 pt-6">
                <button
                  type="button"
                  onClick={() => setShowEditor(false)}
                  className="btn-secondary"
                >
                  Cancel
                </button>
                <button type="submit" className="btn-primary">
                  {selectedCategory ? 'Update' : 'Create'} Category
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}