import React from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

const faqCategories = [
  {
    title: "General Questions",
    faqs: [
      {
        question: "What is Sigmaverse?",
        answer: "Sigmaverse is India's first integrated advertising marketplace that connects advertisers with premium media owners across multiple channels including TV, Radio, Print, OOH, Digital, and more."
      },
      {
        question: "How do I get started with Sigmaverse?",
        answer: "You can start by creating a free account as either an advertiser or media owner. Once registered, you can explore available inventory, create campaigns, or list your media inventory depending on your role."
      }
    ]
  },
  {
    title: "For Advertisers",
    faqs: [
      {
        question: "What is the minimum campaign budget?",
        answer: "Campaign budgets are flexible and depend on your chosen media types and locations. Our AI planner can help optimize your budget across different channels for maximum impact."
      },
      {
        question: "How do I track my campaign performance?",
        answer: "Sigmaverse provides a comprehensive dashboard with real-time analytics, performance metrics, and detailed reports. You can track reach, engagement, and ROI for all your campaigns."
      }
    ]
  },
  {
    title: "For Media Owners",
    faqs: [
      {
        question: "How do I list my inventory?",
        answer: "You can list your inventory either individually or through bulk upload using our Excel/PPT templates. Our team will verify your listings before making them live on the platform."
      },
      {
        question: "How does the payment process work?",
        answer: "Payments are processed securely through our platform. Once a campaign is successfully executed and verified, the payment is released to your registered bank account."
      }
    ]
  },
  {
    title: "Technical Questions",
    faqs: [
      {
        question: "Is my data secure on Sigmaverse?",
        answer: "Yes, we implement industry-standard security measures including encryption, secure authentication, and regular security audits to protect your data."
      },
      {
        question: "What file formats are supported for media uploads?",
        answer: "We support common image formats (JPG, PNG), documents (PDF, DOCX), and video formats (MP4, AVI) for media uploads. Each file should be under 10MB."
      }
    ]
  }
];

export default function FAQPage() {
  const [openCategory, setOpenCategory] = React.useState<number | null>(0);
  const [openQuestions, setOpenQuestions] = React.useState<Record<number, boolean>>({});

  const toggleQuestion = (categoryIndex: number, questionIndex: number) => {
    setOpenQuestions(prev => ({
      ...prev,
      [`${categoryIndex}-${questionIndex}`]: !prev[`${categoryIndex}-${questionIndex}`]
    }));
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-4xl font-bold text-gray-900 mb-8">Frequently Asked Questions</h1>
      
      <div className="space-y-8">
        {faqCategories.map((category, categoryIndex) => (
          <div key={categoryIndex} className="border border-gray-200 rounded-lg overflow-hidden">
            <button
              className="w-full flex items-center justify-between p-4 bg-gray-50 text-left"
              onClick={() => setOpenCategory(openCategory === categoryIndex ? null : categoryIndex)}
            >
              <h2 className="text-xl font-semibold text-gray-900">{category.title}</h2>
              {openCategory === categoryIndex ? (
                <ChevronUp className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDown className="h-5 w-5 text-gray-500" />
              )}
            </button>

            {openCategory === categoryIndex && (
              <div className="p-4 space-y-4">
                {category.faqs.map((faq, questionIndex) => (
                  <div key={questionIndex} className="border-b border-gray-200 last:border-0 pb-4">
                    <button
                      className="w-full flex items-center justify-between text-left"
                      onClick={() => toggleQuestion(categoryIndex, questionIndex)}
                    >
                      <span className="font-medium text-gray-900">{faq.question}</span>
                      {openQuestions[`${categoryIndex}-${questionIndex}`] ? (
                        <ChevronUp className="h-5 w-5 text-gray-500" />
                      ) : (
                        <ChevronDown className="h-5 w-5 text-gray-500" />
                      )}
                    </button>
                    
                    {openQuestions[`${categoryIndex}-${questionIndex}`] && (
                      <p className="mt-2 text-gray-600">{faq.answer}</p>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <div className="mt-12 bg-purple-50 rounded-lg p-6 text-center">
        <h3 className="text-lg font-medium text-purple-900 mb-2">Still have questions?</h3>
        <p className="text-purple-700 mb-4">
          Our team is here to help you with any questions you might have.
        </p>
        <div className="flex justify-center space-x-4">
          <a href="/contact" className="btn-primary">
            Contact Support
          </a>
          <a href="mailto:support@sigmaverse.in" className="btn-secondary">
            Email Us
          </a>
        </div>
      </div>
    </div>
  );
}