import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { ArrowLeft, Calendar, User, Share2, Facebook, Twitter, Linkedin, Copy, Mail } from 'lucide-react';
import { toast } from 'react-toastify';
import SEO from '../../components/SEO';

// ... rest of the imports and article data ...

export default function Article() {
  const { slug } = useParams();
  const article = articles[slug as keyof typeof articles];

  if (!article) {
    return (
      <div className="max-w-4xl mx-auto px-4 py-12">
        <SEO 
          title="Article Not Found"
          description="The requested article could not be found."
        />
        <div className="text-center">
          <h1 className="text-2xl font-bold text-gray-900 mb-4">Article Not Found</h1>
          <Link to="/blog" className="text-purple-600 hover:text-purple-500">
            Return to Blog
          </Link>
        </div>
      </div>
    );
  }

  // Extract first paragraph for meta description
  const description = article.content
    .split('</p>')[0]
    .replace(/<[^>]+>/g, '')
    .trim();

  // Extract keywords from content
  const keywords = article.content
    .toLowerCase()
    .match(/\b\w{4,}\b/g)
    ?.filter((word, index, array) => array.indexOf(word) === index)
    .slice(0, 10);

  return (
    <div className="max-w-4xl mx-auto px-4 py-12">
      <SEO 
        title={article.title}
        description={description}
        image={article.image}
        article={true}
        author={article.author}
        publishedTime={new Date(article.date).toISOString()}
        tags={[article.category, ...keywords]}
      />

      {/* Rest of the article component remains the same */}
    </div>
  );
}