import React from 'react';
import { Routes, Route } from 'react-router-dom';
import MagazineSubcategories from './MagazineSubcategories';
import MagazineInventory from './MagazineInventory';
import MagazineProduct from './MagazineProduct';

export default function MagazineCategory() {
  return (
    <Routes>
      <Route index element={<MagazineSubcategories />} />
      <Route path=":subcategory" element={<MagazineInventory />} />
      <Route path=":subcategory/:id" element={<MagazineProduct />} />
    </Routes>
  );
}