import React from 'react';

interface ProductFeaturesProps {
  features: string[];
}

export default function ProductFeatures({ features }: ProductFeaturesProps) {
  return (
    <div className="mb-6">
      <h2 className="text-lg font-medium text-gray-900 mb-4">Features & Benefits</h2>
      <div className="grid grid-cols-2 gap-3">
        {features.map((feature) => (
          <div
            key={feature}
            className="flex items-center p-3 bg-purple-50 rounded-lg"
          >
            <span className="text-sm text-purple-700">{feature}</span>
          </div>
        ))}
      </div>
    </div>
  );
}