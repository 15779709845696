import React from 'react';
import { Mail, Phone, MapPin, MessageSquare } from 'lucide-react';

export default function Contact() {
  const openWhatsApp = (number: string) => {
    window.open(`https://wa.me/${number.replace(/[^0-9]/g, '')}`, '_blank');
  };

  return (
    <div className="pt-24"> {/* Added top padding to account for header */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl font-bold text-gray-900 mb-6">Contact Us</h1>
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">Get in Touch</h2>
              <form className="space-y-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Name</label>
                  <input type="text" className="mt-1 input" />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email</label>
                  <input type="email" className="mt-1 input" />
                </div>
                
                <div>
                  <label className="block text-sm font-medium text-gray-700">Message</label>
                  <textarea rows={4} className="mt-1 input"></textarea>
                </div>
                
                <button type="submit" className="btn-primary w-full">
                  Send Message
                </button>
              </form>
            </div>
            
            <div>
              <h2 className="text-2xl font-semibold text-gray-900 mb-6">Contact Information</h2>
              <div className="space-y-6">
                <div className="flex items-start">
                  <Mail className="h-6 w-6 text-purple-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">Email</h3>
                    <p className="text-gray-600">contact@sigmaverse.in</p>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <Phone className="h-6 w-6 text-purple-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">Phone</h3>
                    <div className="space-y-2">
                      <div>
                        <p className="text-gray-600">Advertisers & Sales:</p>
                        <div className="flex items-center space-x-2">
                          <p className="font-medium">+91-8494955955</p>
                          <button 
                            onClick={() => openWhatsApp('918494955955')}
                            className="text-green-600 hover:text-green-700"
                          >
                            <MessageSquare className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                      <div>
                        <p className="text-gray-600">Media Owners:</p>
                        <div className="flex items-center space-x-2">
                          <p className="font-medium">+91-8546911911</p>
                          <button 
                            onClick={() => openWhatsApp('918546911911')}
                            className="text-green-600 hover:text-green-700"
                          >
                            <MessageSquare className="h-5 w-5" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="flex items-start">
                  <MapPin className="h-6 w-6 text-purple-600 mt-1" />
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-gray-900">Office</h3>
                    <div className="space-y-4">
                      <div>
                        <p className="font-medium text-gray-900">Corporate Office:</p>
                        <p className="text-gray-600">
                          T-Hub, Plot No 1/C, Sy No 83/1,<br />
                          Raidurgam panmaktha Hyderabad Knowledge City,<br />
                          Serilingampally, Hyderabad,<br />
                          Telangana 500081
                        </p>
                      </div>
                      <div>
                        <p className="font-medium text-gray-900">Registered Office:</p>
                        <p className="text-gray-600">
                          4-2/9/2, Shaikpet Main Rd,<br />
                          Friends Colony, Puppal Guda,<br />
                          Manikonda, Hyderabad,<br />
                          Telangana 500089
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}