import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Tv, Clock, DollarSign } from 'lucide-react';
import { MOCK_TV } from '../../../data/mockTV';

export default function TVInventory() {
  const { channelId } = useParams();
  const channel = MOCK_TV.find(ch => ch.id === channelId);

  if (!channel) {
    return <div>Channel not found</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{channel.name}</h1>
          <p className="text-gray-600">Available Ad Spots</p>
        </div>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>Sort by Price</option>
            <option>Sort by Time Slot</option>
            <option>Sort by Duration</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {channel.inventory.map((item) => (
          <Link
            key={item.id}
            to={`/category/tv/${channelId}/${item.id}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all"
          >
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {item.type}
              </h3>

              <div className="space-y-2 mb-4">
                <div className="flex items-center text-sm text-gray-600">
                  <Clock className="h-4 w-4 mr-2" />
                  {item.timeSlot}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Tv className="h-4 w-4 mr-2" />
                  {item.duration}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <DollarSign className="h-4 w-4 mr-2" />
                  ₹{item.price.toLocaleString()}
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {channel.features.slice(0, 2).map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}