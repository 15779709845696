import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Target, Users, DollarSign, ArrowRight, MessageSquare } from 'lucide-react';

export default function AdvertiserSolutions() {
  const openWhatsApp = () => {
    window.open('https://wa.me/918494955955', '_blank');
  };

  return (
    <div className="pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center mb-16">
          <motion.h1 
            className="text-4xl font-bold text-gray-900 mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            Solutions for Advertisers
          </motion.h1>
          <motion.p 
            className="text-xl text-gray-600 max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            Transform your advertising strategy with our comprehensive suite of solutions
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {[
            {
              title: "AI Campaign Planning",
              description: "Get data-driven recommendations for optimal media mix and budget allocation",
              icon: Brain,
              color: "bg-purple-100 text-purple-600"
            },
            {
              title: "Premium Inventory",
              description: "Access verified media inventory across all channels",
              icon: Target,
              color: "bg-blue-100 text-blue-600"
            },
            {
              title: "Real-time Analytics",
              description: "Track and optimize campaign performance across channels",
              icon: DollarSign,
              color: "bg-green-100 text-green-600"
            }
          ].map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="bg-white rounded-lg shadow-sm p-6"
            >
              <div className={`rounded-full w-12 h-12 ${feature.color} flex items-center justify-center mb-4`}>
                <feature.icon className="h-6 w-6" />
              </div>
              <h3 className="text-xl font-semibold mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>

        <div className="bg-purple-50 rounded-lg p-8 mb-16">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-2xl font-bold text-purple-900 mb-4">
              Need Help Planning Your Campaign?
            </h2>
            <p className="text-purple-700 mb-8">
              Our team can help you create the perfect media mix for your campaign objectives and budget.
            </p>
            <div className="flex justify-center space-x-4">
              <button className="btn-primary">
                Try AI Planner
              </button>
              <button 
                onClick={openWhatsApp}
                className="btn-secondary flex items-center"
              >
                <MessageSquare className="mr-2 h-5 w-5" />
                Chat with Us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}