import React from 'react';
import ComingSoon from './ComingSoon';

export default function Webinars() {
  return (
    <ComingSoon
      title="Webinars Coming Soon"
      description="Join our upcoming webinars featuring industry experts discussing advertising strategies, trends, and best practices. Register to stay updated."
      image="https://images.unsplash.com/photo-1591115765373-5207764f72e7"
    />
  );
}