import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { MapPin, Users, Clock, DollarSign } from 'lucide-react';
import { MOCK_AIRPORTS } from '../../../data/mockAirport';

export default function AirportInventory() {
  const { airportId } = useParams();
  const airport = MOCK_AIRPORTS.find(airport => airport.id === airportId);

  if (!airport) {
    return <div>Airport not found</div>;
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <div>
          <h1 className="text-2xl font-bold text-gray-900">{airport.name}</h1>
          <p className="text-gray-600">Available Ad Spots</p>
        </div>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Terminals</option>
            {airport.terminals.map(terminal => (
              <option key={terminal}>{terminal}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {airport.inventory.map((item) => (
          <Link
            key={item.id}
            to={`/category/airport/${airportId}/${item.id}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all"
          >
            <div className="relative h-48">
              <img
                src={item.images[0]}
                alt={item.type}
                className="w-full h-full object-cover"
              />
              <div className="absolute top-2 right-2">
                <span className={`px-2 py-1 text-xs font-medium rounded-full ${
                  item.availability === 'available'
                    ? 'bg-green-100 text-green-800'
                    : 'bg-yellow-100 text-yellow-800'
                }`}>
                  {item.availability}
                </span>
              </div>
            </div>

            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {item.type}
              </h3>

              <div className="space-y-2 mb-4">
                <div className="flex items-center text-sm text-gray-600">
                  <MapPin className="h-4 w-4 mr-2" />
                  {item.location}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Users className="h-4 w-4 mr-2" />
                  {item.footfall}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <DollarSign className="h-4 w-4 mr-2" />
                  ₹{item.price.toLocaleString()}
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {item.features.slice(0, 2).map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}