import React from 'react';
import { Routes, Route } from 'react-router-dom';
import EventsSubcategories from './EventsSubcategories';
import EventsInventory from './EventsInventory';
import EventsProduct from './EventsProduct';

export default function EventsCategory() {
  return (
    <Routes>
      <Route index element={<EventsSubcategories />} />
      <Route path=":subcategory" element={<EventsInventory />} />
      <Route path=":subcategory/:id" element={<EventsProduct />} />
    </Routes>
  );
}