import React from 'react';

const clients = [
  {
    name: 'Kotak Mahindra Bank',
    logo: '/logos/kotak.png'
  },
  {
    name: 'Havells',
    logo: '/logos/havells.png'
  },
  {
    name: 'Lloyd',
    logo: '/logos/lloyd.png'
  },
  {
    name: 'Reliance Retail',
    logo: '/logos/reliance-retail.png'
  },
  {
    name: 'IDFC Bank',
    logo: '/logos/idfc.png'
  },
  {
    name: 'GSquare Realtors',
    logo: '/logos/gsquare.png'
  },
  {
    name: 'ICFAI Group',
    logo: '/logos/icfai.png'
  },
  {
    name: 'KFC',
    logo: '/logos/kfc.png'
  },
  {
    name: 'Heritage Foods',
    logo: '/logos/heritage.png'
  },
  {
    name: 'ACT Internet',
    logo: '/logos/act.png'
  }
];

export default function ClientLogos() {
  return (
    <section className="py-16 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-center text-2xl font-bold text-gray-900 mb-12">
          Trusted by Leading Brands
        </h2>
        <div className="grid grid-cols-2 md:grid-cols-5 gap-8 items-center">
          {clients.map((client) => (
            <div 
              key={client.name}
              className="flex items-center justify-center p-4 grayscale hover:grayscale-0 transition-all"
            >
              <img
                src={client.logo}
                alt={client.name}
                className="max-h-12 w-auto object-contain"
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}