import axios from 'axios';

const api = axios.create({
  baseURL: import.meta.env.VITE_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Add auth token to requests
api.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

// Handle response errors
api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/signin';
    }
    return Promise.reject(error);
  }
);

export const leads = {
  create: (data: any) => api.post('/leads', data),
  getAll: () => api.get('/leads'),
  update: (id: string, data: any) => api.put(`/leads/${id}`, data)
};

export const campaigns = {
  create: (data: any) => api.post('/campaigns', data),
  getAll: () => api.get('/campaigns'),
  getById: (id: string) => api.get(`/campaigns/${id}`),
  update: (id: string, data: any) => api.put(`/campaigns/${id}`, data)
};

export const inventory = {
  create: (data: any) => api.post('/inventory', data),
  getAll: () => api.get('/inventory'),
  getById: (id: string) => api.get(`/inventory/${id}`),
  update: (id: string, data: any) => api.put(`/inventory/${id}`, data)
};

export const mediaOwners = {
  create: (data: any) => api.post('/media-owners', data),
  getAll: () => api.get('/media-owners'),
  getById: (id: string) => api.get(`/media-owners/${id}`)
};

export default api;