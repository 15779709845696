export const MOCK_VEHICLE = [
  {
    id: 'cab-1',
    title: 'Premium Cab Fleet Branding',
    type: 'cab_fleet',
    location: {
      city: 'Mumbai',
      area: 'Pan City',
      routes: ['Airport', 'Business Districts', 'Suburbs']
    },
    specifications: {
      fleetSize: '500 cars',
      coverage: 'Pan City',
      format: 'Full Car Wrap',
      duration: 'Minimum 3 months'
    },
    pricing: {
      basePrice: 150000,
      unit: 'per_month',
      minimumDuration: 3
    },
    features: [
      'GPS Tracked',
      'Premium Sedan Fleet',
      'High Visibility',
      'Route Optimization'
    ],
    metrics: {
      dailyTrips: '2000+',
      monthlyReach: '150,000+',
      targetAudience: 'Business Travelers'
    },
    images: [
      'https://images.unsplash.com/photo-1549317661-bd32c8ce0db2',
      'https://images.unsplash.com/photo-1549317661-bd32c8ce0db3'
    ],
    availability: {
      status: 'available',
      nextAvailable: '2024-03-15'
    }
  },
  {
    id: 'bus-1',
    title: 'City Bus Fleet Advertising',
    type: 'bus_wrap',
    location: {
      city: 'Delhi',
      area: 'Pan City',
      routes: ['Major Routes', 'Business Corridors']
    },
    specifications: {
      fleetSize: '200 buses',
      coverage: 'Pan City',
      format: 'Full Bus Wrap',
      duration: 'Minimum 6 months'
    },
    pricing: {
      basePrice: 250000,
      unit: 'per_month',
      minimumDuration: 6
    },
    features: [
      'High Impact',
      'Mass Reach',
      'Route Selection',
      'Long Duration'
    ],
    metrics: {
      dailyPassengers: '50,000+',
      monthlyReach: '1,500,000+',
      targetAudience: 'Mass Market'
    },
    images: [
      'https://images.unsplash.com/photo-1570125909232-eb263c188f7e',
      'https://images.unsplash.com/photo-1570125909233-eb263c188f7f'
    ],
    availability: {
      status: 'available',
      nextAvailable: '2024-04-01'
    }
  }
];

export const VEHICLE_CATEGORIES = [
  {
    id: 'cab_fleet',
    name: 'Cab Fleet Branding',
    description: 'Premium taxi and ride-sharing fleet advertising',
    icon: 'Car'
  },
  {
    id: 'bus_wrap',
    name: 'Bus Wraps',
    description: 'City bus and interstate coach branding',
    icon: 'Bus'
  },
  {
    id: 'truck_ads',
    name: 'Truck Advertisements',
    description: 'Commercial vehicle and delivery fleet branding',
    icon: 'Truck'
  },
  {
    id: 'auto_ads',
    name: 'Auto Rickshaw Ads',
    description: 'Local auto rickshaw advertising',
    icon: 'Navigation'
  }
];