import { create } from 'zustand';
import { authService } from '../services/auth';
import { wsService } from '../services/websocket';

interface CategoryField {
  name: string;
  type: 'text' | 'number' | 'select' | 'boolean';
  required: boolean;
  options?: string[];
}

interface Subcategory {
  id: string;
  name: string;
  description: string;
  inventoryCount: number;
}

interface Category {
  id: string;
  name: string;
  icon: string;
  description: string;
  fields: CategoryField[];
  subcategories: Subcategory[];
}

interface CategoryState {
  categories: Category[];
  loading: boolean;
  error: string | null;
  fetchCategories: () => Promise<void>;
  addCategory: (category: Omit<Category, 'id'>) => Promise<void>;
  updateCategory: (id: string, updates: Partial<Category>) => Promise<void>;
  deleteCategory: (id: string) => Promise<void>;
  addSubcategory: (categoryId: string, subcategory: Omit<Subcategory, 'id'>) => Promise<void>;
  updateSubcategory: (categoryId: string, subcategoryId: string, updates: Partial<Subcategory>) => Promise<void>;
  deleteSubcategory: (categoryId: string, subcategoryId: string) => Promise<void>;
}

const useCategoryStore = create<CategoryState>((set) => ({
  categories: [],
  loading: false,
  error: null,

  fetchCategories: async () => {
    set({ loading: true });
    try {
      const response = await authService.getAuthenticatedRequest('/api/admin/categories');
      const data = await response.json();
      set({ categories: data, loading: false });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  addCategory: async (category) => {
    set({ loading: true });
    try {
      const response = await authService.getAuthenticatedRequest('/api/admin/categories', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(category)
      });
      const newCategory = await response.json();
      set(state => ({
        categories: [...state.categories, newCategory],
        loading: false
      }));
      wsService.emit('categoryAdded', newCategory);
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  updateCategory: async (id, updates) => {
    set({ loading: true });
    try {
      const response = await authService.getAuthenticatedRequest(`/api/admin/categories/${id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(updates)
      });
      const updatedCategory = await response.json();
      set(state => ({
        categories: state.categories.map(cat => 
          cat.id === id ? updatedCategory : cat
        ),
        loading: false
      }));
      wsService.emit('categoryUpdated', updatedCategory);
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  deleteCategory: async (id) => {
    set({ loading: true });
    try {
      await authService.getAuthenticatedRequest(`/api/admin/categories/${id}`, {
        method: 'DELETE'
      });
      set(state => ({
        categories: state.categories.filter(cat => cat.id !== id),
        loading: false
      }));
      wsService.emit('categoryDeleted', id);
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  addSubcategory: async (categoryId, subcategory) => {
    set({ loading: true });
    try {
      const response = await authService.getAuthenticatedRequest(
        `/api/admin/categories/${categoryId}/subcategories`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(subcategory)
        }
      );
      const newSubcategory = await response.json();
      set(state => ({
        categories: state.categories.map(cat => 
          cat.id === categoryId
            ? { ...cat, subcategories: [...cat.subcategories, newSubcategory] }
            : cat
        ),
        loading: false
      }));
      wsService.emit('subcategoryAdded', { categoryId, subcategory: newSubcategory });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  updateSubcategory: async (categoryId, subcategoryId, updates) => {
    set({ loading: true });
    try {
      const response = await authService.getAuthenticatedRequest(
        `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(updates)
        }
      );
      const updatedSubcategory = await response.json();
      set(state => ({
        categories: state.categories.map(cat => 
          cat.id === categoryId
            ? {
                ...cat,
                subcategories: cat.subcategories.map(sub =>
                  sub.id === subcategoryId ? updatedSubcategory : sub
                )
              }
            : cat
        ),
        loading: false
      }));
      wsService.emit('subcategoryUpdated', { categoryId, subcategory: updatedSubcategory });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  },

  deleteSubcategory: async (categoryId, subcategoryId) => {
    set({ loading: true });
    try {
      await authService.getAuthenticatedRequest(
        `/api/admin/categories/${categoryId}/subcategories/${subcategoryId}`,
        { method: 'DELETE' }
      );
      set(state => ({
        categories: state.categories.map(cat => 
          cat.id === categoryId
            ? {
                ...cat,
                subcategories: cat.subcategories.filter(sub => sub.id !== subcategoryId)
              }
            : cat
        ),
        loading: false
      }));
      wsService.emit('subcategoryDeleted', { categoryId, subcategoryId });
    } catch (error) {
      set({ error: error.message, loading: false });
    }
  }
}));

// Setup WebSocket listeners
wsService.subscribe('categoryUpdate', () => {
  useCategoryStore.getState().fetchCategories();
});

export default useCategoryStore;