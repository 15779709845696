export const MOCK_RADIO = [
  {
    id: 'radio-mirchi',
    name: 'Radio Mirchi',
    frequency: '98.3 FM',
    language: 'Hindi',
    city: 'Mumbai',
    logo: 'https://images.unsplash.com/photo-1598488035139-bdbb2231ce04',
    reach: '2.5Cr+ Monthly',
    priceRange: '₹5,000 - ₹50,000',
    inventory: [
      {
        id: 'rm-1',
        type: 'RJ Mention',
        duration: '30 seconds',
        timeSlot: 'Morning Drive Time',
        price: 25000,
        availability: 'available'
      },
      {
        id: 'rm-2',
        type: 'Sponsored Show',
        duration: '2 hours',
        timeSlot: 'Evening Drive Time',
        price: 75000,
        availability: 'available'
      }
    ],
    features: ['High Engagement', 'Local Connect', 'Live Integration']
  },
  {
    id: 'red-fm',
    name: 'Red FM',
    frequency: '93.5 FM',
    language: 'Hindi',
    city: 'Delhi',
    logo: 'https://images.unsplash.com/photo-1598488035139-bdbb2231ce05',
    reach: '2Cr+ Monthly',
    priceRange: '₹4,000 - ₹45,000',
    inventory: [
      {
        id: 'rf-1',
        type: 'Spot Ad',
        duration: '20 seconds',
        timeSlot: 'All Day',
        price: 15000,
        availability: 'available'
      },
      {
        id: 'rf-2',
        type: 'Contest Integration',
        duration: '1 hour',
        timeSlot: 'Afternoon',
        price: 50000,
        availability: 'available'
      }
    ],
    features: ['Youth Connect', 'City Events', 'Brand Integration']
  }
];

export const RADIO_CATEGORIES = [
  {
    id: 'fm',
    name: 'FM Radio',
    description: 'Leading FM radio stations across cities',
    icon: 'Radio'
  },
  {
    id: 'community',
    name: 'Community Radio',
    description: 'Local radio stations with focused reach',
    icon: 'Users'
  },
  {
    id: 'digital',
    name: 'Digital Radio',
    description: 'Online radio and podcast platforms',
    icon: 'Globe'
  }
];