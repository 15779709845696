import React from 'react';
import { Routes, Route } from 'react-router-dom';
import VehicleSubcategories from './VehicleSubcategories';
import VehicleInventory from './VehicleInventory';
import VehicleProduct from './VehicleProduct';

export default function VehicleCategory() {
  return (
    <Routes>
      <Route index element={<VehicleSubcategories />} />
      <Route path=":subcategory" element={<VehicleInventory />} />
      <Route path=":subcategory/:id" element={<VehicleProduct />} />
    </Routes>
  );
}