export const MOCK_EVENTS = [
  {
    id: 'ipl-2024',
    title: 'IPL 2024',
    type: 'sports',
    dates: {
      start: '2024-03-22',
      end: '2024-05-26'
    },
    location: {
      city: 'Multiple Cities',
      venues: ['10 Major Stadiums', 'Fan Parks']
    },
    specifications: {
      expectedAttendance: '1Cr+',
      matches: '74',
      format: 'Cricket Tournament',
      broadcast: 'TV + Digital'
    },
    sponsorshipOptions: [
      {
        id: 'ipl-1',
        type: 'Title Sponsor',
        price: 50000000,
        benefits: [
          'Jersey Branding',
          'Stadium Branding',
          'TV Integration',
          'Digital Rights',
          'Player Meet & Greet',
          'Hospitality Box Access'
        ],
        deliverables: {
          branding: [
            'Front Jersey Logo - All Teams',
            'Boundary Rope Branding',
            'Sight Screen Branding',
            'LED Perimeter Boards'
          ],
          media: [
            '3000+ seconds of TV visibility per match',
            'Integration in Digital Live Stream',
            'Social Media Integration'
          ],
          activation: [
            'Fan Park Activation Rights',
            'Stadium Activation Zone',
            'Digital Meet & Greet with Players'
          ]
        },
        availability: 'available',
        duration: 'Full Season'
      },
      {
        id: 'ipl-2',
        type: 'Associate Sponsor',
        price: 25000000,
        benefits: [
          'Ground Branding',
          'Digital Integration',
          'Hospitality Access',
          'Activation Rights'
        ],
        deliverables: {
          branding: [
            'LED Perimeter Boards',
            'Team Dugout Branding',
            'Back of Practice Jersey'
          ],
          media: [
            '1500+ seconds of TV visibility per match',
            'Digital Platform Integration',
            'Social Media Posts'
          ],
          activation: [
            'Stadium Activation Rights',
            'Digital Contests',
            'Meet & Greet with Players (2 Sessions)'
          ]
        },
        availability: 'available',
        duration: 'Full Season'
      }
    ],
    features: [
      'Massive Reach',
      'Multi-city Presence',
      'High Engagement',
      'Premium Association'
    ],
    images: [
      'https://images.unsplash.com/photo-1540747913346-19e32dc3e97e',
      'https://images.unsplash.com/photo-1540747913346-19e32dc3e97f'
    ]
  },
  {
    id: 'auto-expo',
    title: 'Auto Expo 2024',
    type: 'trade_show',
    dates: {
      start: '2024-04-15',
      end: '2024-04-21'
    },
    location: {
      city: 'Delhi',
      venues: ['India Expo Mart']
    },
    specifications: {
      expectedAttendance: '500,000+',
      exhibitors: '1000+',
      area: '100,000 sqm',
      format: 'Trade Exhibition'
    },
    sponsorshipOptions: [
      {
        id: 'ae-1',
        type: 'Pavilion Sponsor',
        price: 10000000,
        benefits: [
          'Dedicated Pavilion',
          'Branding Rights',
          'VIP Access',
          'Lead Generation'
        ],
        deliverables: {
          branding: [
            'Entrance Arch Branding',
            'Pavilion Naming Rights',
            'Direction Boards',
            'Exhibition Guide Presence'
          ],
          media: [
            'Press Conference Opportunity',
            'Media Coverage',
            'Social Media Posts',
            'Email Marketing Integration'
          ],
          activation: [
            'Product Launch Space',
            'Tech Talk Session',
            'Innovation Showcase',
            'Networking Lounge'
          ]
        },
        availability: 'available',
        duration: 'Full Event'
      },
      {
        id: 'ae-2',
        type: 'Technology Partner',
        price: 5000000,
        benefits: [
          'Logo Presence',
          'Exhibition Space',
          'Digital Coverage',
          'Speaking Slot'
        ],
        deliverables: {
          branding: [
            'Logo on Event Website',
            'Mobile App Integration',
            'Digital Displays',
            'Badge Branding'
          ],
          media: [
            'Press Release Coverage',
            'Industry Interview',
            'Digital Content Creation',
            'Event Photography'
          ],
          activation: [
            'Innovation Zone',
            'Tech Demo Area',
            'Workshop Session',
            'B2B Meetings'
          ]
        },
        availability: 'available',
        duration: 'Full Event'
      }
    ],
    features: [
      'Industry Leaders',
      'Media Coverage',
      'B2B Networking',
      'Product Launches'
    ],
    images: [
      'https://images.unsplash.com/photo-1542282088-72c9c27ed0cd',
      'https://images.unsplash.com/photo-1542282088-72c9c27ed0ce'
    ]
  },
  {
    id: 'music-festival',
    title: 'Sunburn Music Festival 2024',
    type: 'entertainment',
    dates: {
      start: '2024-12-28',
      end: '2024-12-31'
    },
    location: {
      city: 'Goa',
      venues: ['Vagator Beach']
    },
    specifications: {
      expectedAttendance: '200,000+',
      stages: '4',
      artists: '100+',
      format: 'Music Festival'
    },
    sponsorshipOptions: [
      {
        id: 'sf-1',
        type: 'Presenting Sponsor',
        price: 20000000,
        benefits: [
          'Title Integration',
          'Main Stage Branding',
          'Exclusive Zone',
          'VIP Access'
        ],
        deliverables: {
          branding: [
            'Event Title Integration',
            'Main Stage Naming Rights',
            'Entry Arch Branding',
            'VIP Area Branding'
          ],
          media: [
            'TV Commercial Spots',
            'Radio Integration',
            'Digital Campaign',
            'Influencer Collaboration'
          ],
          activation: [
            'Brand Experience Zone',
            'Product Sampling',
            'Artist Meet & Greet',
            'Social Media Activation'
          ]
        },
        availability: 'available',
        duration: 'Full Event'
      },
      {
        id: 'sf-2',
        type: 'Stage Partner',
        price: 8000000,
        benefits: [
          'Stage Branding',
          'Artist Integration',
          'Activation Zone',
          'Digital Rights'
        ],
        deliverables: {
          branding: [
            'Stage Name Integration',
            'LED Wall Branding',
            'Photo-op Zones',
            'Wristband Branding'
          ],
          media: [
            'Live Stream Integration',
            'Social Media Posts',
            'PR Coverage',
            'After Movie Feature'
          ],
          activation: [
            'Gaming Zone',
            'Product Display',
            'Contest Integration',
            'Sampling Rights'
          ]
        },
        availability: 'available',
        duration: 'Full Event'
      }
    ],
    features: [
      'Youth Connect',
      'High Energy',
      'Social Media Buzz',
      'Brand Integration'
    ],
    images: [
      'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c3',
      'https://images.unsplash.com/photo-1533174072545-7a4b6ad7a6c4'
    ]
  },
  {
    id: 'tech-summit',
    title: 'India Tech Summit 2024',
    type: 'conferences',
    dates: {
      start: '2024-09-15',
      end: '2024-09-17'
    },
    location: {
      city: 'Bangalore',
      venues: ['Bangalore International Exhibition Centre']
    },
    specifications: {
      expectedAttendance: '25,000+',
      speakers: '200+',
      tracks: '8',
      format: 'Conference & Exhibition'
    },
    sponsorshipOptions: [
      {
        id: 'ts-1',
        type: 'Diamond Sponsor',
        price: 15000000,
        benefits: [
          'Keynote Session',
          'Exhibition Space',
          'Workshop Track',
          'VIP Networking'
        ],
        deliverables: {
          branding: [
            'Main Hall Branding',
            'Registration Area',
            'Digital Displays',
            'Conference App'
          ],
          media: [
            'Press Conference',
            'Industry Report Feature',
            'Digital Coverage',
            'Video Interview'
          ],
          activation: [
            'Innovation Lab',
            'Startup Mentoring',
            'Networking Dinner',
            'Award Ceremony'
          ]
        },
        availability: 'available',
        duration: 'Full Event'
      },
      {
        id: 'ts-2',
        type: 'Track Sponsor',
        price: 5000000,
        benefits: [
          'Track Naming',
          'Speaking Slot',
          'Exhibition Booth',
          'Lead Generation'
        ],
        deliverables: {
          branding: [
            'Track Room Branding',
            'Session Recordings',
            'Conference Bag Insert',
            'Digital Signage'
          ],
          media: [
            'Track Promotion',
            'Social Media Coverage',
            'Email Marketing',
            'Blog Feature'
          ],
          activation: [
            'Workshop Session',
            'Meet the Experts',
            'Product Demo',
            'Networking Break'
          ]
        },
        availability: 'available',
        duration: 'Full Event'
      }
    ],
    features: [
      'Tech Leaders',
      'Innovation Focus',
      'Networking',
      'Knowledge Sharing'
    ],
    images: [
      'https://images.unsplash.com/photo-1540575467063-178a50c2df87',
      'https://images.unsplash.com/photo-1540575467063-178a50c2df88'
    ]
  },
  {
    id: 'fashion-week',
    title: 'India Fashion Week 2024',
    type: 'entertainment',
    dates: {
      start: '2024-10-10',
      end: '2024-10-15'
    },
    location: {
      city: 'Mumbai',
      venues: ['Jio World Convention Centre']
    },
    specifications: {
      expectedAttendance: '50,000+',
      designers: '100+',
      shows: '45+',
      format: 'Fashion Shows & Exhibition'
    },
    sponsorshipOptions: [
      {
        id: 'fw-1',
        type: 'Title Sponsor',
        price: 25000000,
        benefits: [
          'Title Integration',
          'Grand Finale Show',
          'Designer Collaborations',
          'VIP Access'
        ],
        deliverables: {
          branding: [
            'Event Title Integration',
            'Runway Branding',
            'Backdrop & Photo-wall',
            'VIP Lounge Branding'
          ],
          media: [
            'TV Coverage Rights',
            'Digital Live Stream',
            'Fashion Magazine Coverage',
            'Influencer Integration'
          ],
          activation: [
            'Pop-up Store',
            'Designer Meet & Greet',
            'Fashion Awards',
            'Style Zone'
          ]
        },
        availability: 'available',
        duration: 'Full Event'
      },
      {
        id: 'fw-2',
        type: 'Show Sponsor',
        price: 10000000,
        benefits: [
          'Show Integration',
          'Designer Collaboration',
          'Media Coverage',
          'Activation Rights'
        ],
        deliverables: {
          branding: [
            'Show Title Integration',
            'Backdrop Branding',
            'Digital Screens',
            'Gift Bags'
          ],
          media: [
            'Show Coverage',
            'Social Media Posts',
            'PR Integration',
            'Photo Gallery'
          ],
          activation: [
            'Product Display',
            'Styling Session',
            'Fashion Contest',
            'Influencer Meet'
          ]
        },
        availability: 'available',
        duration: 'Per Show'
      }
    ],
    features: [
      'Fashion Industry',
      'Celebrity Presence',
      'Media Coverage',
      'Luxury Connect'
    ],
    images: [
      'https://images.unsplash.com/photo-1537832816519-689ad163238b',
      'https://images.unsplash.com/photo-1537832816519-689ad163238c'
    ]
  }
];

export const EVENT_CATEGORIES = [
  {
    id: 'sports',
    name: 'Sports Events',
    description: 'Major sporting tournaments and leagues',
    icon: 'Trophy'
  },
  {
    id: 'trade_show',
    name: 'Trade Shows',
    description: 'Industry exhibitions and trade fairs',
    icon: 'Building'
  },
  {
    id: 'entertainment',
    name: 'Entertainment',
    description: 'Music festivals and cultural events',
    icon: 'Music'
  },
  {
    id: 'conferences',
    name: 'Conferences',
    description: 'Business and tech conferences',
    icon: 'Users'
  }
];