import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Book, Users, Globe, DollarSign, Search } from 'lucide-react';
import { MOCK_MAGAZINES } from '../../../data/mockMagazines';

export default function MagazineInventory() {
  const { subcategory } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('all');

  const magazines = MOCK_MAGAZINES.filter(magazine => 
    magazine.category === subcategory &&
    (selectedLanguage === 'all' || magazine.language === selectedLanguage) &&
    (searchTerm === '' || magazine.name.toLowerCase().includes(searchTerm.toLowerCase()))
  );

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">
          {subcategory?.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')} Magazines
        </h1>
        
        <div className="flex items-center space-x-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search magazines..."
              className="pl-10 input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <select
            className="input"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            <option value="all">All Languages</option>
            <option value="English">English</option>
            <option value="Hindi">Hindi</option>
            <option value="Regional">Regional</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {magazines.map((magazine) => (
          <div key={magazine.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="relative h-48">
              <img
                src={magazine.image}
                alt={magazine.name}
                className="w-full h-full object-cover"
              />
            </div>

            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">{magazine.name}</h3>
              
              <div className="space-y-2 mb-4">
                <div className="flex items-center text-sm text-gray-600">
                  <Globe className="h-4 w-4 mr-2" />
                  {magazine.language} | {magazine.frequency}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Users className="h-4 w-4 mr-2" />
                  {magazine.circulation} Circulation
                </div>
              </div>

              <div className="space-y-3 mb-4">
                {magazine.adSpots.map((spot) => (
                  <div key={spot.id} className="flex justify-between text-sm">
                    <span className="text-gray-600">{spot.type}</span>
                    <span className="font-medium">₹{spot.price.toLocaleString()}</span>
                  </div>
                ))}
              </div>

              <div className="flex flex-wrap gap-2">
                {magazine.features.map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>

              <div className="mt-4 pt-4 border-t border-gray-200">
                <Link
                  to={`/category/magazine/${subcategory}/${magazine.id}`}
                  className="btn-primary w-full text-center"
                >
                  View Ad Options
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}