import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Calendar, Users, MapPin, DollarSign, Search, Filter } from 'lucide-react';
import { MOCK_EVENTS } from '../../../data/mockEvents';

export default function EventsInventory() {
  const { subcategory } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCity, setSelectedCity] = useState('all');
  const [priceRange, setPriceRange] = useState('all');

  const filteredEvents = MOCK_EVENTS.filter(event => {
    const matchesType = event.type === subcategory;
    const matchesSearch = event.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCity = selectedCity === 'all' || event.location.city === selectedCity;
    const lowestPrice = Math.min(...event.sponsorshipOptions.map(opt => opt.price));
    const matchesPrice = priceRange === 'all' || 
      (priceRange === 'under10l' && lowestPrice < 1000000) ||
      (priceRange === '10l-50l' && lowestPrice >= 1000000 && lowestPrice <= 5000000) ||
      (priceRange === 'above50l' && lowestPrice > 5000000);

    return matchesType && matchesSearch && matchesCity && matchesPrice;
  });

  const cities = [...new Set(MOCK_EVENTS.map(event => event.location.city))];

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">
          {subcategory?.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')} Events
        </h1>

        <div className="flex items-center space-x-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search events..."
              className="pl-10 input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <select
            className="input"
            value={selectedCity}
            onChange={(e) => setSelectedCity(e.target.value)}
          >
            <option value="all">All Cities</option>
            {cities.map(city => (
              <option key={city} value={city}>{city}</option>
            ))}
          </select>

          <select
            className="input"
            value={priceRange}
            onChange={(e) => setPriceRange(e.target.value)}
          >
            <option value="all">All Prices</option>
            <option value="under10l">Under ₹10L</option>
            <option value="10l-50l">₹10L - ₹50L</option>
            <option value="above50l">Above ₹50L</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredEvents.map((event) => (
          <Link
            key={event.id}
            to={`/category/events/${subcategory}/${event.id}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all"
          >
            <div className="relative h-48">
              <img
                src={event.images[0]}
                alt={event.title}
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
              <div className="absolute bottom-4 left-4 text-white">
                <h3 className="text-lg font-medium">{event.title}</h3>
                <p className="text-sm opacity-90">{event.location.city}</p>
              </div>
            </div>

            <div className="p-6">
              <div className="space-y-2 mb-4">
                <div className="flex items-center text-sm text-gray-600">
                  <Calendar className="h-4 w-4 mr-2" />
                  {new Date(event.dates.start).toLocaleDateString()} - {new Date(event.dates.end).toLocaleDateString()}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Users className="h-4 w-4 mr-2" />
                  {event.specifications.expectedAttendance} Expected Attendance
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <DollarSign className="h-4 w-4 mr-2" />
                  Sponsorships from ₹{Math.min(...event.sponsorshipOptions.map(opt => opt.price)).toLocaleString()}
                </div>
              </div>

              <div className="flex flex-wrap gap-2">
                {event.features.slice(0, 2).map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>

              <div className="mt-4 pt-4 border-t border-gray-100">
                <div className="text-sm text-gray-600">
                  {event.sponsorshipOptions.length} Sponsorship Packages Available
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}