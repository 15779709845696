import React from 'react';
import { useParams } from 'react-router-dom';
import { Globe, Users, DollarSign, TrendingUp, Target } from 'lucide-react';
import { MOCK_DIGITAL } from '../../../data/mockDigital';

export default function DigitalProduct() {
  const { id } = useParams();
  const inventory = MOCK_DIGITAL.find(item => item.id === id);

  if (!inventory) {
    return <div>Inventory not found</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm p-6">
      <div className="mb-6">
        <div className="flex items-center justify-between">
          <h1 className="text-2xl font-bold text-gray-900">{inventory.title}</h1>
          <span className={`px-3 py-1 text-sm font-medium rounded-full ${
            inventory.availability.status === 'available'
              ? 'bg-green-100 text-green-800'
              : 'bg-yellow-100 text-yellow-800'
          }`}>
            {inventory.availability.status}
          </span>
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <DollarSign className="h-4 w-4 mr-2" />
                Minimum Budget
              </div>
              <p className="text-lg font-semibold">
                ₹{inventory.pricing.minimumBudget.toLocaleString()}
              </p>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Users className="h-4 w-4 mr-2" />
                Reach/Views
              </div>
              <p className="text-lg font-semibold">
                {inventory.metrics.avgViews || inventory.metrics.impressions || inventory.metrics.reach}
              </p>
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Specifications</h2>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(inventory.specifications).map(([key, value]) => (
                <div key={key} className="text-sm">
                  <span className="text-gray-600">
                    {key.charAt(0).toUpperCase() + key.slice(1)}:
                  </span>
                  <span className="ml-2 font-medium">{value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Features</h2>
            <div className="flex flex-wrap gap-2">
              {inventory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-3 py-1 bg-purple-50 text-purple-700 text-sm rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </div>
        </div>

        <div>
          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Performance Metrics</h2>
            <div className="space-y-4">
              {Object.entries(inventory.metrics).map(([key, value]) => (
                <div key={key} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  <span className="text-sm text-gray-600">
                    {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                  </span>
                  <span className="font-medium">{value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="flex space-x-4">
            <button className="flex-1 btn-primary">
              Start Campaign
            </button>
            <button className="flex-1 btn-secondary">
              Download Media Kit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}