import React from 'react';
import { motion } from 'framer-motion';
import { Globe, Users, Award, Target, Building, BarChart2 } from 'lucide-react';

export default function About() {
  const features = [
    {
      icon: Globe,
      title: 'Pan-India Coverage',
      description: 'Access advertising opportunities across all major cities and regions in India'
    },
    {
      icon: Users,
      title: 'Trusted Network',
      description: '2500+ active advertisers and 500+ verified media owners'
    },
    {
      icon: Award,
      title: 'Premium Quality',
      description: 'Curated selection of premium advertising spaces and media options'
    },
    {
      icon: Target,
      title: 'AI-Powered Planning',
      description: 'Smart campaign recommendations based on your objectives and budget'
    },
    {
      icon: Building,
      title: 'Industry Experience',
      description: 'Deep expertise in media planning and campaign execution'
    },
    {
      icon: BarChart2,
      title: 'Real-time Analytics',
      description: 'Comprehensive performance tracking and optimization tools'
    }
  ];

  return (
    <div className="pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        {/* Hero Section */}
        <div className="text-center mb-16">
          <motion.h1 
            className="text-4xl font-bold text-gray-900 mb-6"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            About Sigmaverse
          </motion.h1>
          <motion.p 
            className="text-xl text-gray-600 max-w-3xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            India's first integrated advertising marketplace connecting brands with premium media opportunities across multiple channels.
          </motion.p>
        </div>

        {/* Mission & Vision */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 mb-16">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="bg-white rounded-lg shadow-sm p-8"
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Mission</h2>
            <p className="text-gray-600">
              To revolutionize the advertising landscape by providing a unified platform that simplifies 
              the discovery, planning, and execution of advertising campaigns across all media channels.
            </p>
          </motion.div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="bg-white rounded-lg shadow-sm p-8"
          >
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Our Vision</h2>
            <p className="text-gray-600">
              To become India's most trusted advertising platform, empowering businesses of all sizes 
              to reach their target audience effectively and efficiently through innovative technology solutions.
            </p>
          </motion.div>
        </div>

        {/* Features Grid */}
        <div className="mb-16">
          <h2 className="text-3xl font-bold text-gray-900 text-center mb-12">Why Choose Sigmaverse</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {features.map((feature, index) => (
              <motion.div
                key={feature.title}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: index * 0.1 }}
                className="bg-white rounded-lg shadow-sm p-6"
              >
                <div className="rounded-full bg-purple-100 w-12 h-12 flex items-center justify-center mb-4">
                  <feature.icon className="h-6 w-6 text-purple-600" />
                </div>
                <h3 className="text-xl font-semibold text-gray-900 mb-2">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </motion.div>
            ))}
          </div>
        </div>

        {/* Stats Section */}
        <div className="bg-purple-600 rounded-lg shadow-lg p-8 text-white">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {[
              { label: 'Active Advertisers', value: '2,500+' },
              { label: 'Media Partners', value: '500+' },
              { label: 'Campaigns Delivered', value: '10,000+' },
              { label: 'Cities Covered', value: '100+' }
            ].map((stat) => (
              <div key={stat.label} className="text-center">
                <div className="text-3xl font-bold mb-2">{stat.value}</div>
                <div className="text-purple-100">{stat.label}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}