import React from 'react';
import { Routes, Route } from 'react-router-dom';
import RadioSubcategories from './RadioSubcategories';
import RadioInventory from './RadioInventory';
import RadioProduct from './RadioProduct';

export default function RadioCategory() {
  return (
    <Routes>
      <Route index element={<RadioSubcategories />} />
      <Route path=":stationId" element={<RadioInventory />} />
      <Route path=":stationId/:inventoryId" element={<RadioProduct />} />
    </Routes>
  );
}