export interface Campaign {
  id: string;
  name: string;
  client: string;
  status: 'active' | 'planned' | 'completed';
  budget: number;
  startDate: string;
  endDate: string;
  reach: string;
  objective: string;
  channels: string[];
  metrics: {
    impressions: number;
    engagement: number;
    conversion: number;
  };
}

export const MOCK_CAMPAIGNS: Campaign[] = [
  {
    id: '1',
    name: 'Summer Collection Launch 2024',
    client: 'Fashion Brand X',
    status: 'active',
    budget: 2500000,
    startDate: '2024-03-01',
    endDate: '2024-04-30',
    reach: '5M+',
    objective: 'Product Launch',
    channels: ['Television', 'Digital', 'OOH'],
    metrics: {
      impressions: 3200000,
      engagement: 420000,
      conversion: 28000
    }
  },
  {
    id: '2',
    name: 'IPL Season Campaign',
    client: 'Sports Brand Y',
    status: 'planned',
    budget: 5000000,
    startDate: '2024-04-15',
    endDate: '2024-05-31',
    reach: '10M+',
    objective: 'Brand Awareness',
    channels: ['Television', 'Digital', 'Radio'],
    metrics: {
      impressions: 0,
      engagement: 0,
      conversion: 0
    }
  },
  {
    id: '3',
    name: 'Festive Season Sale',
    client: 'E-commerce Platform Z',
    status: 'completed',
    budget: 3500000,
    startDate: '2023-10-01',
    endDate: '2023-11-15',
    reach: '8M+',
    objective: 'Sales',
    channels: ['Television', 'Digital', 'OOH', 'Radio'],
    metrics: {
      impressions: 7500000,
      engagement: 950000,
      conversion: 85000
    }
  }
];