import React from 'react';

export default function CookiePolicy() {
  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <h1 className="text-3xl font-bold text-gray-900 mb-8">Cookie Policy</h1>
      
      <div className="prose prose-lg">
        <p className="text-gray-600 mb-6">Last updated: March 15, 2024</p>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">1. What Are Cookies</h2>
          <p className="text-gray-600">
            Cookies are small text files that are placed on your computer or mobile device when you visit our website. They help us make your visit to our site better and provide more personalized services to you.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">2. Types of Cookies We Use</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Essential cookies: Required for basic site functionality</li>
            <li>Analytics cookies: Help us understand how visitors use our site</li>
            <li>Functional cookies: Remember your preferences</li>
            <li>Advertising cookies: Deliver relevant advertisements</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">3. How We Use Cookies</h2>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>To remember your preferences and settings</li>
            <li>To improve site navigation</li>
            <li>To analyze site usage and performance</li>
            <li>To provide personalized content and advertising</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">4. Managing Cookies</h2>
          <p className="text-gray-600">
            You can control and manage cookies in various ways:
          </p>
          <ul className="list-disc pl-6 text-gray-600 space-y-2">
            <li>Browser settings to block or delete cookies</li>
            <li>Third-party opt-out tools</li>
            <li>Our cookie consent tool</li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">5. Third-Party Cookies</h2>
          <p className="text-gray-600">
            We use cookies from trusted third parties for analytics, advertising, and social media integration. These parties may also set cookies when you use our site.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">6. Updates to This Policy</h2>
          <p className="text-gray-600">
            We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated revision date.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">7. Contact Us</h2>
          <p className="text-gray-600">
            If you have any questions about our Cookie Policy, please contact us at:
          </p>
          <ul className="list-none pl-6 text-gray-600 space-y-2">
            <li>Email: privacy@sigmaverse.in</li>
            <li>Phone: +91-8494955955</li>
          </ul>
        </section>
      </div>
    </div>
  );
}