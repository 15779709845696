import React from 'react';

interface ProductGalleryProps {
  images: string[];
  title: string;
}

export default function ProductGallery({ images, title }: ProductGalleryProps) {
  return (
    <div>
      <div className="relative h-96 mb-4 rounded-lg overflow-hidden">
        <img
          src={images[0]}
          alt={title}
          className="w-full h-full object-cover"
        />
      </div>
      <div className="grid grid-cols-4 gap-2">
        {images.slice(1).map((image, index) => (
          <div key={index} className="relative h-20 rounded-lg overflow-hidden">
            <img
              src={image}
              alt={`${title} ${index + 2}`}
              className="w-full h-full object-cover"
            />
          </div>
        ))}
      </div>
    </div>
  );
}