import React from 'react';
import { Routes, Route } from 'react-router-dom';
import InfluencerSubcategories from './InfluencerSubcategories';
import InfluencerInventory from './InfluencerInventory';
import InfluencerProfilePage from './InfluencerProfilePage';

export default function InfluencerCategory() {
  return (
    <Routes>
      <Route index element={<InfluencerSubcategories />} />
      <Route path=":subcategory" element={<InfluencerInventory />} />
      <Route path=":subcategory/:id" element={<InfluencerProfilePage />} />
    </Routes>
  );
}