import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Plus } from 'lucide-react';
import useAuth from '../hooks/useAuth';

interface AddToCampaignButtonProps {
  onAdd: () => void;
  className?: string;
}

export default function AddToCampaignButton({ onAdd, className }: AddToCampaignButtonProps) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const handleClick = () => {
    if (!isAuthenticated) {
      navigate('/signin', { 
        state: { 
          returnUrl: window.location.pathname,
          message: 'Please sign in to add inventory to your campaign'
        }
      });
      return;
    }
    onAdd();
  };

  return (
    <button 
      onClick={handleClick}
      className={`btn-primary flex items-center justify-center ${className}`}
    >
      <Plus className="h-4 w-4 mr-2" />
      Add to Campaign
    </button>
  );
}