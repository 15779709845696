import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Target, Users, DollarSign } from 'lucide-react';

const features = [
  {
    title: "AI-Powered Planning",
    description: "Get data-driven recommendations for your campaign strategy",
    icon: Brain,
    color: "bg-purple-100 text-purple-600"
  },
  {
    title: "All Media Types",
    description: "One platform for all your advertising needs",
    icon: Target,
    color: "bg-blue-100 text-blue-600"
  },
  {
    title: "Verified Media Owners",
    description: "Access premium inventory from trusted partners",
    icon: Users,
    color: "bg-green-100 text-green-600"
  },
  {
    title: "Real-Time Analytics",
    description: "Track and optimize campaign performance",
    icon: DollarSign,
    color: "bg-pink-100 text-pink-600"
  }
];

export default function Features() {
  return (
    <section className="py-20 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold text-gray-900">Why Choose Sigmaverse?</h2>
          <p className="mt-4 text-lg text-gray-600">
            The smarter way to plan, book, and manage your advertising campaigns
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={feature.title}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: index * 0.1 }}
              className="text-center"
            >
              <div className={`mx-auto w-12 h-12 rounded-full ${feature.color} flex items-center justify-center mb-4`}>
                <feature.icon className="h-6 w-6" />
              </div>
              <h3 className="text-lg font-medium text-gray-900 mb-2">{feature.title}</h3>
              <p className="text-gray-600">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
}