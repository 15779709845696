import React from 'react';
import { Link } from 'react-router-dom';
import { Facebook, Twitter, Instagram, Linkedin, Youtube, Mail, Phone, MapPin, MessageSquare } from 'lucide-react';

export default function Footer() {
  const openWhatsApp = (number: string) => {
    window.open(`https://wa.me/${number.replace(/[^0-9]/g, '')}`, '_blank');
  };

  const navigation = {
    solutions: [
      { name: 'For Advertisers', href: '/advertiser' },
      { name: 'For Media Owners', href: '/media-owner' },
      { name: 'Discover Media', href: '/discover' },
      { name: 'Digital Marketing', href: '/digital' },
      { name: 'PR Services', href: '/discover/pr' },
    ],
    resources: [
      { name: 'Case Studies', href: '/case-studies' },
      { name: 'Blog', href: '/blog' },
      { name: 'Industry Reports', href: '/industry-reports' },
      { name: 'Webinars', href: '/webinars' },
      { name: 'Media Kit', href: '/media-kit' },
    ],
    company: [
      { name: 'About Us', href: '/about' },
      { name: 'FAQ', href: '/faq' },
      { name: 'Contact', href: '/contact' },
    ],
    legal: [
      { name: 'Privacy Policy', href: '/privacy' },
      { name: 'Terms of Service', href: '/terms' },
      { name: 'Cookie Policy', href: '/cookies' },
      { name: 'Disclaimer', href: '/disclaimer' },
    ],
    social: [
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/sigmaverse',
        icon: Linkedin,
        color: 'hover:text-[#0077b5]'
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/sigmaverse_in',
        icon: Twitter,
        color: 'hover:text-[#1DA1F2]'
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/sigmaverse.in',
        icon: Instagram,
        color: 'hover:text-[#E4405F]'
      },
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/sigmaverse.in',
        icon: Facebook,
        color: 'hover:text-[#1877F2]'
      },
      {
        name: 'YouTube',
        href: 'https://www.youtube.com/@sigmaverse',
        icon: Youtube,
        color: 'hover:text-[#FF0000]'
      }
    ],
  };

  return (
    <footer className="bg-gray-900" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {/* Contact Information */}
        <div className="flex flex-col md:flex-row justify-between items-start mb-8 pb-8 border-b border-gray-700">
          <div className="flex items-center space-x-2 mb-4 md:mb-0">
            <Mail className="h-5 w-5 text-gray-400" />
            <a href="mailto:contact@sigmaverse.in" className="text-gray-300 hover:text-white">
              contact@sigmaverse.in
            </a>
          </div>
          
          <div className="flex flex-col space-y-2">
            <div className="flex items-center space-x-2">
              <Phone className="h-5 w-5 text-gray-400" />
              <span className="text-gray-300">Advertisers & Sales: +91-8494955955</span>
              <button 
                onClick={() => openWhatsApp('918494955955')}
                className="text-green-500 hover:text-green-400"
              >
                <MessageSquare className="h-5 w-5" />
              </button>
            </div>
            <div className="flex items-center space-x-2">
              <Phone className="h-5 w-5 text-gray-400" />
              <span className="text-gray-300">Media Owners: +91-8546911911</span>
              <button 
                onClick={() => openWhatsApp('918546911911')}
                className="text-green-500 hover:text-green-400"
              >
                <MessageSquare className="h-5 w-5" />
              </button>
            </div>
          </div>
        </div>

        {/* Main Footer Content */}
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <Link to="/" className="flex items-center">
              <span className="text-2xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                Sigmaverse
              </span>
            </Link>
            <p className="text-gray-400 text-base leading-relaxed">
              India's first integrated advertising marketplace connecting brands with premium media opportunities across traditional and digital channels.
            </p>
            <div className="flex space-x-5">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={`text-gray-400 transition-colors ${item.color}`}
                  aria-label={item.name}
                >
                  <item.icon className="h-6 w-6" />
                </a>
              ))}
            </div>
          </div>

          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.solutions.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white transition-colors"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Resources
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.resources.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white transition-colors"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Company
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white transition-colors"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Legal
                </h3>
                <ul className="mt-4 space-y-4">
                  {navigation.legal.map((item) => (
                    <li key={item.name}>
                      <Link
                        to={item.href}
                        className="text-base text-gray-300 hover:text-white transition-colors"
                      >
                        {item.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        {/* Address Section */}
        <div className="mt-8 pt-8 border-t border-gray-700">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex items-start">
              <MapPin className="h-5 w-5 text-gray-400 mr-2 mt-1" />
              <div>
                <p className="text-gray-300 font-medium">Corporate Office:</p>
                <p className="text-gray-400">
                  T-Hub, Plot No 1/C, Sy No 83/1,<br />
                  Raidurgam panmaktha Hyderabad Knowledge City,<br />
                  Serilingampally, Hyderabad,<br />
                  Telangana 500081
                </p>
              </div>
            </div>
            <div className="flex items-start">
              <MapPin className="h-5 w-5 text-gray-400 mr-2 mt-1" />
              <div>
                <p className="text-gray-300 font-medium">Registered Office:</p>
                <p className="text-gray-400">
                  4-2/9/2, Shaikpet Main Rd,<br />
                  Friends Colony, Puppal Guda,<br />
                  Manikonda, Hyderabad,<br />
                  Telangana 500089
                </p>
              </div>
            </div>
          </div>
          <p className="mt-8 text-center text-gray-400">
            &copy; {new Date().getFullYear()} Sigmaverse. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}