import React from 'react';
import { Link } from 'react-router-dom';
import { Film, Users, Building, MapPin } from 'lucide-react';

const subcategories = [
  {
    id: 'multiplex',
    name: 'Multiplex Theatres',
    icon: Building,
    description: 'Premium advertising spaces in modern multiplex chains',
    count: 156,
    features: ['Digital Screens', 'Food Court Branding', 'Lobby Displays']
  },
  {
    id: 'single_screen',
    name: 'Single Screen Theatres',
    icon: Film,
    description: 'High-impact advertising in traditional theatres',
    count: 89,
    features: ['Mass Reach', 'Traditional Audience', 'Local Impact']
  },
  {
    id: 'luxury',
    name: 'Luxury Cinemas',
    icon: Users,
    description: 'Premium advertising for luxury audience',
    count: 42,
    features: ['Premium Audience', 'Exclusive Spaces', 'VIP Areas']
  },
  {
    id: 'drive_in',
    name: 'Drive-In Theatres',
    icon: MapPin,
    description: 'Unique outdoor cinema advertising opportunities',
    count: 12,
    features: ['Outdoor Spaces', 'Unique Format', 'Event Integration']
  }
];

export default function TheatreSubcategories() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Theatre Advertising</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Cities</option>
            <option>Mumbai</option>
            <option>Delhi</option>
            <option>Bangalore</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {subcategories.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={subcategory.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center">
                  <subcategory.icon className="h-6 w-6 text-purple-600 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">
                    {subcategory.name}
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  {subcategory.description}
                </p>
              </div>
              <span className="text-sm text-gray-500">
                {subcategory.count} spots
              </span>
            </div>

            <div className="mt-4 flex flex-wrap gap-2">
              {subcategory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}