import React, { useState } from 'react';
import { Shield, Lock, Globe, AlertTriangle } from 'lucide-react';

export default function SecuritySettings() {
  const [settings, setSettings] = useState({
    ipWhitelist: '',
    sessionTimeout: 30,
    requireTwoFactor: true,
    criticalActionVerification: true
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setSettings(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleToggle = (name: string) => {
    setSettings(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle settings update
    console.log('Settings updated:', settings);
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Security Settings</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        <div className="lg:col-span-2 space-y-8">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-6">Access Control</h2>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  IP Whitelist
                </label>
                <textarea
                  name="ipWhitelist"
                  rows={4}
                  className="mt-1 input"
                  placeholder="Enter IP addresses (one per line)"
                  value={settings.ipWhitelist}
                  onChange={handleChange}
                />
                <p className="mt-1 text-sm text-gray-500">
                  Only these IP addresses will be allowed to access the admin panel
                </p>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Session Timeout (minutes)
                </label>
                <input
                  type="number"
                  name="sessionTimeout"
                  className="mt-1 input"
                  value={settings.sessionTimeout}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-6">Authentication</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-700">Two-Factor Authentication</p>
                  <p className="text-sm text-gray-500">Require 2FA for all admin users</p>
                </div>
                <input
                  type="checkbox"
                  checked={settings.requireTwoFactor}
                  onChange={() => handleToggle('requireTwoFactor')}
                  className="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                />
              </div>

              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm font-medium text-gray-700">Critical Action Verification</p>
                  <p className="text-sm text-gray-500">
                    Require additional verification for critical operations
                  </p>
                </div>
                <input
                  type="checkbox"
                  checked={settings.criticalActionVerification}
                  onChange={() => handleToggle('criticalActionVerification')}
                  className="rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="space-y-6">
          <div className="bg-white rounded-lg shadow-sm p-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Security Overview</h2>
            <div className="space-y-4">
              <div className="flex items-center justify-between">
                <span className="text-sm text-gray-600">Security Score</span>
                <span className="text-sm font-medium text-green-600">85/100</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2">
                <div className="bg-green-600 h-2 rounded-full" style={{ width: '85%' }} />
              </div>
            </div>
          </div>

          <div className="bg-yellow-50 rounded-lg p-6">
            <div className="flex items-start">
              <AlertTriangle className="h-5 w-5 text-yellow-500 mt-0.5" />
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">Security Recommendations</h3>
                <ul className="mt-2 text-sm text-yellow-700 list-disc pl-5 space-y-1">
                  <li>Enable two-factor authentication</li>
                  <li>Update IP whitelist regularly</li>
                  <li>Review active sessions</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}