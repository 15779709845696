import React, { useState } from 'react';
import { X, Calendar } from 'lucide-react';

interface AddToCampaignModalProps {
  inventory: {
    id: string;
    type: string;
    location: string;
    price: number;
    mediaOwner: {
      id: string;
      name: string;
    };
  };
  onClose: () => void;
  onAdd: (campaignId: string | 'new', details: any) => void;
  existingCampaigns: any[];
}

export default function AddToCampaignModal({
  inventory,
  onClose,
  onAdd,
  existingCampaigns
}: AddToCampaignModalProps) {
  const [selectedCampaign, setSelectedCampaign] = useState<string | 'new'>('new');
  const [newCampaignName, setNewCampaignName] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onAdd(selectedCampaign, {
      name: newCampaignName,
      startDate,
      endDate,
      inventory
    });
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg max-w-md w-full p-6">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">Add to Campaign</h2>
          <button onClick={onClose}>
            <X className="h-6 w-6 text-gray-500" />
          </button>
        </div>

        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Select Campaign
            </label>
            <select
              className="input"
              value={selectedCampaign}
              onChange={(e) => setSelectedCampaign(e.target.value)}
            >
              <option value="new">Create New Campaign</option>
              {existingCampaigns.map((campaign) => (
                <option key={campaign.id} value={campaign.id}>
                  {campaign.name}
                </option>
              ))}
            </select>
          </div>

          {selectedCampaign === 'new' && (
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Campaign Name
              </label>
              <input
                type="text"
                className="input"
                value={newCampaignName}
                onChange={(e) => setNewCampaignName(e.target.value)}
                required
              />
            </div>
          )}

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                className="input"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <input
                type="date"
                className="input"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                required
              />
            </div>
          </div>

          <div className="bg-gray-50 p-4 rounded-lg">
            <h3 className="font-medium text-gray-900 mb-2">Selected Inventory</h3>
            <div className="text-sm text-gray-600">
              <p>{inventory.type}</p>
              <p className="text-sm text-gray-500">{inventory.location}</p>
              <p className="mt-1 font-medium">₹{inventory.price.toLocaleString()}/month</p>
            </div>
          </div>

          <div className="flex justify-end space-x-3 pt-4">
            <button type="button" onClick={onClose} className="btn-secondary">
              Cancel
            </button>
            <button type="submit" className="btn-primary">
              Add to Campaign
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}