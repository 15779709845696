import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Globe, Users, DollarSign, Search, TrendingUp } from 'lucide-react';
import { MOCK_DIGITAL } from '../../../data/mockDigital';

export default function DigitalInventory() {
  const { subcategory } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [budgetRange, setBudgetRange] = useState('all');

  const filteredInventory = MOCK_DIGITAL.filter(item => {
    const matchesSubcategory = item.type === subcategory;
    const matchesSearch = item.title.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesBudget = budgetRange === 'all' || 
      (budgetRange === 'under50k' && item.pricing.minimumBudget < 50000) ||
      (budgetRange === '50k-200k' && item.pricing.minimumBudget >= 50000 && item.pricing.minimumBudget <= 200000) ||
      (budgetRange === 'above200k' && item.pricing.minimumBudget > 200000);

    return matchesSubcategory && matchesSearch && matchesBudget;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">
          {subcategory?.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')} Advertising
        </h1>

        <div className="flex items-center space-x-4">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <input
              type="text"
              placeholder="Search inventory..."
              className="pl-10 input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <select
            className="input"
            value={budgetRange}
            onChange={(e) => setBudgetRange(e.target.value)}
          >
            <option value="all">All Budgets</option>
            <option value="under50k">Under ₹50,000</option>
            <option value="50k-200k">₹50,000 - ₹2,00,000</option>
            <option value="above200k">Above ₹2,00,000</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredInventory.map((item) => (
          <div key={item.id} className="bg-white rounded-lg shadow-sm overflow-hidden">
            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">{item.title}</h3>

              <div className="space-y-2 mb-4">
                <div className="flex items-center text-sm text-gray-600">
                  <Globe className="h-4 w-4 mr-2" />
                  {item.specifications.platform}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <Users className="h-4 w-4 mr-2" />
                  {item.metrics.avgViews || item.metrics.impressions || item.metrics.reach}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <TrendingUp className="h-4 w-4 mr-2" />
                  Engagement: {item.metrics.engagementRate || item.metrics.clickRate || item.metrics.completion}
                </div>
                <div className="flex items-center text-sm text-gray-600">
                  <DollarSign className="h-4 w-4 mr-2" />
                  Min Budget: ₹{item.pricing.minimumBudget.toLocaleString()}
                </div>
              </div>

              <div className="flex flex-wrap gap-2 mb-4">
                {item.features.map((feature) => (
                  <span
                    key={feature}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {feature}
                  </span>
                ))}
              </div>

              <Link
                to={`/category/digital/${subcategory}/${item.id}`}
                className="btn-primary w-full text-center"
              >
                View Details
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}