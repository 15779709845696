import React from 'react';
import { Link } from 'react-router-dom';
import { ShoppingBag, Building, Users, MapPin } from 'lucide-react';

const subcategories = [
  {
    id: 'mall_activation',
    name: 'Mall Activations',
    icon: ShoppingBag,
    description: 'Engage with premium shoppers through mall activities',
    count: 156,
    features: ['High Footfall', 'Premium Audience', 'Event Support']
  },
  {
    id: 'apartment_activation',
    name: 'Apartment Activations',
    icon: Building,
    description: 'Connect with residential communities',
    count: 89,
    features: ['Community Connect', 'Direct Engagement', 'Local Impact']
  },
  {
    id: 'corporate_activation',
    name: 'Corporate Engagements',
    icon: Users,
    description: 'Reach professionals in business environments',
    count: 67,
    features: ['B2B Focus', 'Professional Audience', 'Brand Building']
  },
  {
    id: 'local_activation',
    name: 'Local Area Activations',
    icon: MapPin,
    description: 'Target specific neighborhoods and localities',
    count: 234,
    features: ['Hyperlocal', 'Community Events', 'Direct Response']
  }
];

export default function BTLSubcategories() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">BTL Advertising</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Cities</option>
            <option>Mumbai</option>
            <option>Delhi</option>
            <option>Bangalore</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {subcategories.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={subcategory.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center">
                  <subcategory.icon className="h-6 w-6 text-purple-600 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">
                    {subcategory.name}
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  {subcategory.description}
                </p>
              </div>
              <span className="text-sm text-gray-500">
                {subcategory.count} spots
              </span>
            </div>

            <div className="mt-4 flex flex-wrap gap-2">
              {subcategory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}