import React from 'react';
import { useParams } from 'react-router-dom';
import { INFLUENCERS } from '../../../data/mockInfluencers';
import { Instagram, Youtube, Twitter, Users, Globe, TrendingUp, MessageSquare } from 'lucide-react';
import ProductActions from '../../../components/ProductActions';

export default function InfluencerProfilePage() {
  const { id } = useParams();
  const influencer = INFLUENCERS.find(inf => inf.id === id);

  if (!influencer) {
    return <div>Influencer not found</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="relative h-64">
        <img
          src={influencer.coverImage}
          alt={influencer.name}
          className="w-full h-full object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
        <div className="absolute bottom-4 left-4 text-white">
          <h1 className="text-3xl font-bold">{influencer.name}</h1>
          <div className="flex items-center mt-2">
            <Globe className="h-4 w-4 mr-2" />
            <span>{influencer.categories.join(', ')}</span>
          </div>
        </div>
      </div>

      <div className="p-6">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
          <div className="bg-pink-50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <Instagram className="h-6 w-6 text-pink-600" />
              <span className="text-sm font-medium">Instagram</span>
            </div>
            <div className="space-y-1">
              <p className="text-2xl font-bold">
                {(influencer.stats.instagram.followers / 1000000).toFixed(1)}M
              </p>
              <p className="text-sm">
                {influencer.stats.instagram.engagement}% Engagement
              </p>
            </div>
          </div>

          {influencer.stats.youtube && (
            <div className="bg-red-50 rounded-lg p-4">
              <div className="flex items-center justify-between mb-2">
                <Youtube className="h-6 w-6 text-red-600" />
                <span className="text-sm font-medium">YouTube</span>
              </div>
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  {(influencer.stats.youtube.subscribers / 1000000).toFixed(1)}M
                </p>
                <p className="text-sm">
                  {influencer.stats.youtube.views}M Views
                </p>
              </div>
            </div>
          )}

          <div className="bg-green-50 rounded-lg p-4">
            <div className="flex items-center justify-between mb-2">
              <TrendingUp className="h-6 w-6 text-green-600" />
              <span className="text-sm font-medium">Overall Engagement</span>
            </div>
            <div className="space-y-1">
              <p className="text-2xl font-bold">{influencer.stats.engagement}%</p>
              <p className="text-sm">Average Rate</p>
            </div>
          </div>
        </div>

        <div className="mb-8">
          <h2 className="text-lg font-medium text-gray-900 mb-4">Available Packages</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {influencer.pricing.map((price) => (
              <div key={price.type} className="border rounded-lg p-4">
                <div className="flex justify-between items-center mb-2">
                  <span className="font-medium">{price.type}</span>
                  <span className="text-sm text-purple-600">₹{price.amount.toLocaleString()}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        <ProductActions
          inventory={{
            id: influencer.id,
            type: 'Influencer Marketing',
            location: influencer.name,
            price: influencer.pricing[0].amount,
            mediaOwner: {
              id: influencer.id,
              name: influencer.name
            }
          }}
        />
      </div>
    </div>
  );
}