import React from 'react';
import { useParams } from 'react-router-dom';
import { MOCK_OOH } from '../../../data/mockOOH';
import ProductGallery from '../../../components/ProductGallery';
import ProductHeader from '../../../components/ProductHeader';
import ProductMetrics from '../../../components/ProductMetrics';
import ProductFeatures from '../../../components/ProductFeatures';
import ProductActions from '../../../components/ProductActions';

export default function OOHProduct() {
  const { id } = useParams();
  const inventory = MOCK_OOH.find(item => item.id === id);

  if (!inventory) {
    return <div>Inventory not found</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="p-6">
          <ProductGallery 
            images={inventory.images} 
            title={inventory.title} 
          />
        </div>

        <div className="p-6">
          <ProductHeader
            title={inventory.title}
            mediaOwner={inventory.mediaOwner.name}
            status={inventory.availability.status}
          />

          <ProductMetrics
            price={inventory.pricing.basePrice}
            viewership={inventory.metrics.dailyTraffic}
            viewershipLabel="Daily Traffic"
          />

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Location Details</h2>
            <div className="space-y-4">
              <div className="text-sm">
                <span className="text-gray-600">Area:</span>
                <span className="ml-2 font-medium">{inventory.location.area}</span>
              </div>
              <div className="text-sm">
                <span className="text-gray-600">City:</span>
                <span className="ml-2 font-medium">{inventory.location.city}</span>
              </div>
              <div className="text-sm">
                <span className="text-gray-600">Size:</span>
                <span className="ml-2 font-medium">{inventory.specifications.size}</span>
              </div>
            </div>
          </div>

          <ProductFeatures features={inventory.features} />

          <ProductActions
            inventory={{
              id: inventory.id,
              type: inventory.title,
              location: `${inventory.location.area}, ${inventory.location.city}`,
              price: inventory.pricing.basePrice,
              mediaOwner: {
                id: inventory.mediaOwner.id,
                name: inventory.mediaOwner.name
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}