// Add more mock inventory data for each category...
export const MOCK_INVENTORY = [
  // BTL Category
  {
    id: 'btl-001',
    title: 'Mall Activation - Phoenix MarketCity',
    category: 'btl',
    subcategory: 'mall_activations',
    mediaOwner: {
      id: 'mo-004',
      name: 'Phoenix Malls',
      verified: true
    },
    location: {
      city: 'Mumbai',
      state: 'Maharashtra',
      address: 'Phoenix MarketCity, Kurla'
    },
    pricing: {
      basePrice: 75000,
      currency: 'INR',
      unit: 'per_day'
    },
    specifications: {
      area: '1000 sq ft',
      footfall: '50,000+ daily',
      timing: '11 AM - 9 PM',
      setup: 'Stage and Sound System Available'
    },
    features: [
      'High Footfall Area',
      'Premium Audience',
      'Event Support',
      'Power Backup'
    ],
    images: [
      'https://images.unsplash.com/photo-1519389950473-47ba0277781c',
      'https://images.unsplash.com/photo-1519389950473-47ba0277781d'
    ],
    availability: {
      status: 'available'
    },
    analytics: {
      views: 25000,
      engagement: 85,
      averageRating: 4.7
    }
  },

  // Vehicle Category
  {
    id: 'vehicle-001',
    title: 'Premium Cab Fleet Branding',
    category: 'vehicle',
    subcategory: 'cab_fleet',
    mediaOwner: {
      id: 'mo-005',
      name: 'Urban Mobility Solutions',
      verified: true
    },
    location: {
      city: 'Bangalore',
      state: 'Karnataka',
      address: 'Pan Bangalore'
    },
    pricing: {
      basePrice: 150000,
      currency: 'INR',
      unit: 'per_month'
    },
    specifications: {
      fleetSize: '100 cars',
      coverage: 'Pan City',
      format: 'Full Car Wrap',
      duration: 'Minimum 3 months'
    },
    features: [
      'GPS Tracked',
      'Premium Sedan Fleet',
      'High Visibility',
      'Route Optimization'
    ],
    images: [
      'https://images.unsplash.com/photo-1549317661-bd32c8ce0db2',
      'https://images.unsplash.com/photo-1549317661-bd32c8ce0db3'
    ],
    availability: {
      status: 'available'
    },
    analytics: {
      views: 180000,
      engagement: 78,
      averageRating: 4.5
    }
  },

  // PR Category
  {
    id: 'pr-001',
    title: 'National Press Release Package',
    category: 'pr',
    subcategory: 'media_relations',
    mediaOwner: {
      id: 'mo-006',
      name: 'PR Newswire India',
      verified: true
    },
    location: {
      city: 'Pan India',
      state: 'All States',
      address: 'National Coverage'
    },
    pricing: {
      basePrice: 125000,
      currency: 'INR',
      unit: 'per_release'
    },
    specifications: {
      coverage: '100+ Media Outlets',
      languages: ['English', 'Hindi'],
      distribution: 'National',
      support: '24/7 Media Monitoring'
    },
    features: [
      'Professional Writing',
      'Media Monitoring',
      'Social Media Boost',
      'Analytics Report'
    ],
    images: [
      'https://images.unsplash.com/photo-1557804506-669a67965ba0',
      'https://images.unsplash.com/photo-1557804506-669a67965ba1'
    ],
    availability: {
      status: 'available'
    },
    analytics: {
      views: 50000,
      engagement: 92,
      averageRating: 4.8
    }
  },

  // Digital Category
  {
    id: 'digital-001',
    title: 'Premium Programmatic Display Package',
    category: 'digital',
    subcategory: 'programmatic',
    mediaOwner: {
      id: 'mo-007',
      name: 'Digital Ad Exchange',
      verified: true
    },
    location: {
      city: 'Pan India',
      state: 'All States',
      address: 'Digital Presence'
    },
    pricing: {
      basePrice: 200000,
      currency: 'INR',
      unit: 'per_month'
    },
    specifications: {
      impressions: '1M+ Monthly',
      targeting: 'Custom Audience',
      formats: ['Display', 'Native', 'Video'],
      platforms: 'Premium Publishers'
    },
    features: [
      'Real-time Bidding',
      'Advanced Targeting',
      'Brand Safety',
      'Performance Analytics'
    ],
    images: [
      'https://images.unsplash.com/photo-1460925895917-afdab827c52f',
      'https://images.unsplash.com/photo-1460925895917-afdab827c52e'
    ],
    availability: {
      status: 'available'
    },
    analytics: {
      views: 1000000,
      engagement: 88,
      averageRating: 4.6
    }
  },

  // DOOH Category
  {
    id: 'dooh-001',
    title: 'Premium Digital Billboard - DLF Cyber City',
    category: 'dooh',
    subcategory: 'digital_billboard',
    mediaOwner: {
      id: 'mo-008',
      name: 'Digital Outdoor Network',
      verified: true
    },
    location: {
      city: 'Gurgaon',
      state: 'Haryana',
      address: 'DLF Cyber City'
    },
    pricing: {
      basePrice: 180000,
      currency: 'INR',
      unit: 'per_month'
    },
    specifications: {
      size: '20ft x 30ft',
      resolution: '4K',
      brightness: '6000 nits',
      viewability: '24/7'
    },
    features: [
      'Real-time Content Updates',
      'Dynamic Scheduling',
      'Weather Triggered Ads',
      'Audience Analytics'
    ],
    images: [
      'https://images.unsplash.com/photo-1515263487990-61b07816b324',
      'https://images.unsplash.com/photo-1515263487990-61b07816b325'
    ],
    availability: {
      status: 'available'
    },
    analytics: {
      views: 300000,
      engagement: 90,
      averageRating: 4.8
    }
  }
];