export const MOCK_MAGAZINES = [
  {
    id: 'vogue',
    name: 'Vogue India',
    category: 'lifestyle',
    circulation: '150,000+',
    language: 'English',
    frequency: 'Monthly',
    adSpots: [
      {
        id: 'vogue-1',
        type: 'Full Page',
        price: 450000,
        dimensions: '21cm x 29.7cm',
        position: 'Inside Front Cover',
        availability: 'available'
      },
      {
        id: 'vogue-2',
        type: 'Double Spread',
        price: 750000,
        dimensions: '42cm x 29.7cm',
        position: 'Center Spread',
        availability: 'available'
      }
    ],
    features: ['Premium Audience', 'High Print Quality', 'Digital Edition'],
    image: 'https://images.unsplash.com/photo-1544441893-675973e31985'
  },
  {
    id: 'forbes',
    name: 'Forbes India',
    category: 'business',
    circulation: '100,000+',
    language: 'English',
    frequency: 'Monthly',
    adSpots: [
      {
        id: 'forbes-1',
        type: 'Full Page',
        price: 350000,
        dimensions: '21cm x 29.7cm',
        position: 'Inside Pages',
        availability: 'available'
      },
      {
        id: 'forbes-2',
        type: 'Half Page',
        price: 200000,
        dimensions: '21cm x 14.8cm',
        position: 'Inside Pages',
        availability: 'available'
      }
    ],
    features: ['Business Leaders', 'Decision Makers', 'Digital Presence'],
    image: 'https://images.unsplash.com/photo-1554774853-719586f82d77'
  }
];