import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TVSubcategories from './TVSubcategories';
import TVInventory from './TVInventory';
import TVProduct from './TVProduct';

export default function TVCategory() {
  return (
    <Routes>
      <Route index element={<TVSubcategories />} />
      <Route path=":channelId" element={<TVInventory />} />
      <Route path=":channelId/:inventoryId" element={<TVProduct />} />
    </Routes>
  );
}