import React from 'react';
import { Link } from 'react-router-dom';
import { Book, Users, Target, Briefcase } from 'lucide-react';

const subcategories = [
  {
    id: 'lifestyle',
    name: 'Lifestyle Magazines',
    icon: Users,
    description: 'Premium advertising in fashion and lifestyle magazines',
    count: 34,
    features: ['Premium Audience', 'High-Quality Print', 'Long Shelf Life']
  },
  {
    id: 'business',
    name: 'Business Magazines',
    icon: Briefcase,
    description: 'Reach business leaders and decision makers',
    count: 28,
    features: ['B2B Focus', 'Industry Specific', 'Professional Audience']
  },
  {
    id: 'niche',
    name: 'Niche Interest',
    icon: Target,
    description: 'Specialized magazines for targeted advertising',
    count: 56,
    features: ['Focused Readership', 'Special Interest', 'Engaged Audience']
  },
  {
    id: 'general',
    name: 'General Interest',
    icon: Book,
    description: 'Wide-reaching general interest publications',
    count: 42,
    features: ['Mass Appeal', 'Wide Distribution', 'Family Readership']
  }
];

export default function MagazineSubcategories() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Magazine Advertising</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Languages</option>
            <option>English</option>
            <option>Hindi</option>
            <option>Regional</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {subcategories.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={subcategory.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center">
                  <subcategory.icon className="h-6 w-6 text-purple-600 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">
                    {subcategory.name}
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  {subcategory.description}
                </p>
              </div>
              <span className="text-sm text-gray-500">
                {subcategory.count} spots
              </span>
            </div>

            <div className="mt-4 flex flex-wrap gap-2">
              {subcategory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}