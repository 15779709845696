import React from 'react';
import { Link } from 'react-router-dom';
import { Radio, Music, Mic, Globe } from 'lucide-react';
import { MOCK_RADIO } from '../../../data/mockRadio';

const subcategories = [
  {
    id: 'fm_stations',
    name: 'FM Stations',
    icon: Radio,
    description: 'Leading FM radio stations across cities',
    count: MOCK_RADIO.filter(station => station.type === 'fm').length,
    features: ['High Reach', 'Local Connect', 'Live Integration']
  },
  {
    id: 'community',
    name: 'Community Radio',
    icon: Mic,
    description: 'Local radio stations with focused reach',
    count: MOCK_RADIO.filter(station => station.type === 'community').length,
    features: ['Targeted Reach', 'Community Connect', 'Local Impact']
  },
  {
    id: 'digital',
    name: 'Digital Radio',
    icon: Globe,
    description: 'Online radio and podcast platforms',
    count: MOCK_RADIO.filter(station => station.type === 'digital').length,
    features: ['Digital Audience', 'On-demand', 'Analytics']
  },
  {
    id: 'music',
    name: 'Music Stations',
    icon: Music,
    description: '24/7 music programming stations',
    count: MOCK_RADIO.filter(station => station.type === 'music').length,
    features: ['Youth Connect', 'Brand Integration', 'High Engagement']
  }
];

export default function RadioSubcategories() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Radio Advertising</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Cities</option>
            <option>Mumbai</option>
            <option>Delhi</option>
            <option>Bangalore</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {subcategories.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={subcategory.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center">
                  <subcategory.icon className="h-6 w-6 text-purple-600 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">
                    {subcategory.name}
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  {subcategory.description}
                </p>
              </div>
              <span className="text-sm text-gray-500">
                {subcategory.count} stations
              </span>
            </div>

            <div className="mt-4 flex flex-wrap gap-2">
              {subcategory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}