import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  title: string;
  description: string;
  image?: string;
  article?: boolean;
  author?: string;
  publishedTime?: string;
  modifiedTime?: string;
  tags?: string[];
}

export default function SEO({
  title,
  description,
  image,
  article,
  author,
  publishedTime,
  modifiedTime,
  tags
}: SEOProps) {
  const siteUrl = 'https://sigmaverse.co';
  const defaultImage = `${siteUrl}/og-image.jpg`;

  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{`${title} | Sigmaverse`}</title>
      <meta name="description" content={description} />

      {/* Open Graph Tags */}
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content={article ? 'article' : 'website'} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image || defaultImage} />

      {/* Twitter Card Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image || defaultImage} />

      {/* Article Specific Tags */}
      {article && (
        <>
          <meta property="article:published_time" content={publishedTime} />
          {modifiedTime && (
            <meta property="article:modified_time" content={modifiedTime} />
          )}
          <meta property="article:author" content={author} />
          {tags?.map((tag) => (
            <meta key={tag} property="article:tag" content={tag} />
          ))}
        </>
      )}

      {/* JSON-LD Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify(
          article
            ? {
                '@context': 'https://schema.org',
                '@type': 'Article',
                headline: title,
                description: description,
                image: image || defaultImage,
                author: {
                  '@type': 'Person',
                  name: author
                },
                publisher: {
                  '@type': 'Organization',
                  name: 'Sigmaverse',
                  logo: {
                    '@type': 'ImageObject',
                    url: `${siteUrl}/logo.png`
                  }
                },
                datePublished: publishedTime,
                dateModified: modifiedTime || publishedTime
              }
            : {
                '@context': 'https://schema.org',
                '@type': 'WebPage',
                name: title,
                description: description,
                image: image || defaultImage
              }
        )}
      </script>
    </Helmet>
  );
}