import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Instagram, Youtube, Users, TrendingUp } from 'lucide-react';
import { INFLUENCERS } from '../../../data/mockInfluencers';

export default function InfluencerInventory() {
  const { subcategory } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('all');

  const filteredInfluencers = INFLUENCERS.filter(influencer => {
    const matchesCategory = influencer.categories.some(cat => 
      cat.toLowerCase() === subcategory?.toLowerCase()
    );
    const matchesSearch = influencer.name.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesLanguage = selectedLanguage === 'all' || influencer.languages.includes(selectedLanguage);

    return matchesCategory && matchesSearch && matchesLanguage;
  });

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">
          {subcategory?.split('_').map(word => 
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ')} Influencers
        </h1>

        <div className="flex items-center space-x-4">
          <input
            type="text"
            placeholder="Search influencers..."
            className="input"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />

          <select
            className="input"
            value={selectedLanguage}
            onChange={(e) => setSelectedLanguage(e.target.value)}
          >
            <option value="all">All Languages</option>
            <option value="Hindi">Hindi</option>
            <option value="English">English</option>
            <option value="Regional">Regional</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {filteredInfluencers.map((influencer) => (
          <Link
            key={influencer.id}
            to={`/category/influencer/${subcategory}/${influencer.id}`}
            className="bg-white rounded-lg shadow-sm overflow-hidden hover:shadow-md transition-all"
          >
            <div className="relative h-48">
              <img
                src={influencer.coverImage}
                alt={influencer.name}
                className="w-full h-full object-cover"
              />
            </div>

            <div className="p-6">
              <h3 className="text-lg font-medium text-gray-900 mb-2">
                {influencer.name}
              </h3>

              <p className="text-sm text-gray-600 mb-4">{influencer.bio}</p>

              <div className="flex items-center space-x-4 mb-4">
                <div className="flex items-center text-pink-600">
                  <Instagram className="h-4 w-4 mr-1" />
                  <span className="text-sm font-medium">
                    {(influencer.stats.instagram.followers / 1000000).toFixed(1)}M
                  </span>
                </div>
                {influencer.stats.youtube && (
                  <div className="flex items-center text-red-600">
                    <Youtube className="h-4 w-4 mr-1" />
                    <span className="text-sm font-medium">
                      {(influencer.stats.youtube.subscribers / 1000000).toFixed(1)}M
                    </span>
                  </div>
                )}
                <div className="flex items-center text-green-600">
                  <TrendingUp className="h-4 w-4 mr-1" />
                  <span className="text-sm font-medium">
                    {influencer.stats.engagement}%
                  </span>
                </div>
              </div>

              <div className="space-y-2">
                {influencer.pricing.slice(0, 2).map((price) => (
                  <div key={price.type} className="flex justify-between text-sm">
                    <span className="text-gray-600">{price.type}</span>
                    <span className="font-medium">₹{price.amount.toLocaleString()}</span>
                  </div>
                ))}
              </div>

              <div className="mt-4 flex flex-wrap gap-2">
                {influencer.categories.map((category) => (
                  <span
                    key={category}
                    className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                  >
                    {category}
                  </span>
                ))}
              </div>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}