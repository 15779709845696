import React from 'react';
import { Routes, Route } from 'react-router-dom';
import NewspaperSubcategories from './NewspaperSubcategories';
import NewspaperInventory from './NewspaperInventory';
import NewspaperProduct from './NewspaperProduct';

export default function NewspaperCategory() {
  return (
    <Routes>
      <Route index element={<NewspaperSubcategories />} />
      <Route path=":subcategory" element={<NewspaperInventory />} />
      <Route path=":subcategory/:id" element={<NewspaperProduct />} />
    </Routes>
  );
}