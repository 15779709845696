import React from 'react';
import { Link } from 'react-router-dom';
import { Newspaper, Globe, MapPin, Calendar } from 'lucide-react';

const subcategories = [
  {
    id: 'national_daily',
    name: 'National Dailies',
    icon: Globe,
    description: 'Premium advertising in leading national newspapers',
    count: 48,
    features: ['Pan-India Reach', 'Premium Positioning', 'Multiple Editions']
  },
  {
    id: 'regional_daily',
    name: 'Regional Dailies',
    icon: MapPin,
    description: 'Targeted advertising in regional language newspapers',
    count: 124,
    features: ['Regional Impact', 'Language Options', 'Local Connect']
  },
  {
    id: 'business_paper',
    name: 'Business Papers',
    icon: Newspaper,
    description: 'Reach business decision-makers and professionals',
    count: 28,
    features: ['Business Audience', 'Premium Content', 'Focused Reach']
  },
  {
    id: 'supplements',
    name: 'Newspaper Supplements',
    icon: Calendar,
    description: 'Themed supplements and special editions',
    count: 86,
    features: ['Targeted Content', 'Weekend Editions', 'Special Features']
  }
];

export default function NewspaperSubcategories() {
  return (
    <div>
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold text-gray-900">Newspaper Advertising</h1>
        <div className="flex items-center space-x-4">
          <select className="input">
            <option>All Languages</option>
            <option>English</option>
            <option>Hindi</option>
            <option>Regional</option>
          </select>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        {subcategories.map((subcategory) => (
          <Link
            key={subcategory.id}
            to={subcategory.id}
            className="bg-white rounded-lg shadow-sm p-6 hover:shadow-md transition-all"
          >
            <div className="flex items-start justify-between">
              <div className="flex-1">
                <div className="flex items-center">
                  <subcategory.icon className="h-6 w-6 text-purple-600 mr-2" />
                  <h2 className="text-lg font-medium text-gray-900">
                    {subcategory.name}
                  </h2>
                </div>
                <p className="mt-2 text-sm text-gray-600">
                  {subcategory.description}
                </p>
              </div>
              <span className="text-sm text-gray-500">
                {subcategory.count} spots
              </span>
            </div>

            <div className="mt-4 flex flex-wrap gap-2">
              {subcategory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-2 py-1 bg-purple-50 text-purple-700 text-xs rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
}