import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import AdminLogin from '../pages/AdminPanel/Login';
import AdminLayout from '../pages/AdminPanel/Layout';
import AdminDashboard from '../pages/AdminPanel/Dashboard';
import AdminUsers from '../pages/AdminPanel/Users';
import AdminCampaigns from '../pages/AdminPanel/Campaigns';
import AdminApprovals from '../pages/AdminPanel/Approvals';
import AdminAnalytics from '../pages/AdminPanel/Analytics';
import AdminBlog from '../pages/AdminPanel/BlogPosts';
import AdminCategories from '../pages/AdminPanel/CategoryManagement';
import AdminSecurity from '../pages/AdminPanel/SecuritySettings';
import AdminSupport from '../pages/AdminPanel/Support';
import AdminSettings from '../pages/AdminPanel/Settings';

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const isAuthenticated = localStorage.getItem('adminAuth') === 'true';
  return isAuthenticated ? <>{children}</> : <Navigate to="/admin/login" />;
};

export default function AdminRoutes() {
  return (
    <Routes>
      <Route path="login" element={<AdminLogin />} />
      
      <Route path="/*" element={
        <ProtectedRoute>
          <AdminLayout>
            <Routes>
              <Route index element={<AdminDashboard />} />
              <Route path="users" element={<AdminUsers />} />
              <Route path="campaigns" element={<AdminCampaigns />} />
              <Route path="approvals" element={<AdminApprovals />} />
              <Route path="analytics" element={<AdminAnalytics />} />
              <Route path="blog" element={<AdminBlog />} />
              <Route path="categories" element={<AdminCategories />} />
              <Route path="security" element={<AdminSecurity />} />
              <Route path="support" element={<AdminSupport />} />
              <Route path="settings" element={<AdminSettings />} />
            </Routes>
          </AdminLayout>
        </ProtectedRoute>
      } />
    </Routes>
  );
}