import React from 'react';
import { useParams } from 'react-router-dom';
import { MapPin, Users, Clock, DollarSign, Star, Building, Calendar } from 'lucide-react';
import { MOCK_INVENTORY } from '../../../data/mockInventory';

export default function TheatreProduct() {
  const { id } = useParams();
  const inventory = MOCK_INVENTORY.find(item => item.id === id);

  if (!inventory) {
    return <div>Inventory not found</div>;
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div className="p-6">
          <div className="relative h-96 mb-4 rounded-lg overflow-hidden">
            <img
              src={inventory.images[0]}
              alt={inventory.title}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="grid grid-cols-4 gap-2">
            {inventory.images.slice(1).map((image, index) => (
              <div key={index} className="relative h-20 rounded-lg overflow-hidden">
                <img
                  src={image}
                  alt={`${inventory.title} ${index + 2}`}
                  className="w-full h-full object-cover"
                />
              </div>
            ))}
          </div>
        </div>

        <div className="p-6">
          <div className="mb-6">
            <div className="flex items-center justify-between">
              <h1 className="text-2xl font-bold text-gray-900">{inventory.title}</h1>
              <span className={`px-3 py-1 text-sm font-medium rounded-full ${
                inventory.availability.status === 'available'
                  ? 'bg-green-100 text-green-800'
                  : 'bg-yellow-100 text-yellow-800'
              }`}>
                {inventory.availability.status}
              </span>
            </div>
            <div className="flex items-center mt-2">
              <Building className="h-4 w-4 text-gray-400 mr-2" />
              <span className="text-sm text-gray-600">{inventory.mediaOwner.name}</span>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-4 mb-6">
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <DollarSign className="h-4 w-4 mr-2" />
                Pricing
              </div>
              <p className="text-lg font-semibold">
                ₹{inventory.pricing.basePrice.toLocaleString()}
                <span className="text-sm text-gray-500"> {inventory.pricing.unit}</span>
              </p>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <div className="flex items-center text-sm text-gray-600 mb-1">
                <Users className="h-4 w-4 mr-2" />
                Monthly Views
              </div>
              <p className="text-lg font-semibold">
                {inventory.analytics.views.toLocaleString()}
              </p>
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Specifications</h2>
            <div className="grid grid-cols-2 gap-4">
              {Object.entries(inventory.specifications).map(([key, value]) => (
                <div key={key} className="text-sm">
                  <span className="text-gray-600">
                    {key.split('_').map(word => 
                      word.charAt(0).toUpperCase() + word.slice(1)
                    ).join(' ')}:
                  </span>
                  <span className="ml-2 font-medium">{value}</span>
                </div>
              ))}
            </div>
          </div>

          <div className="mb-6">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Features</h2>
            <div className="flex flex-wrap gap-2">
              {inventory.features.map((feature) => (
                <span
                  key={feature}
                  className="px-3 py-1 bg-purple-50 text-purple-700 text-sm rounded-full"
                >
                  {feature}
                </span>
              ))}
            </div>
          </div>

          <div className="flex space-x-4">
            <button className="flex-1 btn-primary">
              Book Now
            </button>
            <button className="flex-1 btn-secondary">
              Contact Media Owner
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}